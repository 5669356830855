import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { CancelState } from './types'
import mutations from './mutations'
import actions from './actions'
import { CancelReasonId } from '@/api'

export const state: CancelState = {
  cancellation: {
    cancellationDate: '',
    cancelReasonId: CancelReasonId.Other,
    cancelExplanation: ''
  },
  covidSuspensionDate: ''
}

const namespaced = true

export const Cancel: Module<CancelState, RootState> = {
  namespaced,
  state,
  mutations,
  actions
}
