import { MutationTree } from 'vuex'
import { AuthState } from './types'
import setDefaultAuthHeaders from './helpers'

const mutations: MutationTree<AuthState> = {
  SET_CURRENT_USER (state, payload: AuthState) {
    state.user = payload?.user || null
    state.authorization = payload?.authorization
    setDefaultAuthHeaders(state)
  }
}

export default mutations
