




















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import Password from 'vue-password-strength-meter'
import { FilePondErrorDescription, FilePondFile, registerPlugin as filePondRegisterPlugin } from 'filepond'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import { AuthenticationApi } from '@/api'

const FilePond = vueFilePond()
filePondRegisterPlugin(FilePondPluginFileValidateType)
filePondRegisterPlugin(FilePondPluginFileValidateSize)

@Component({
  components: {
    Password,
    FilePond
  }
})
export default class FormInput extends Vue {
  @Prop(Object) public readonly inputField!: InputField;

  public overrideErrorDisplay = false
  public interacted: boolean;

  public showPassword = false

  public passwordScore: number | null = null

  @Watch('inputField.value')
  onInputValueChanged () {
    this.$emit('change', this.inputField.value)
  }

  get filePond () {
    const store = this.$store
    return {
      files: [],
      server: {
        url: process.env.VUE_APP_API,
        headers: {
          get Authorization () {
            return `bearer ${store.getters['Auth/getAuthorizationTokens'].accessToken}`
          }
        },
        process: {
          url: 'Upload/Upload'
        },
        revert: {
          url: 'Upload/Delete',
          method: 'POST'
        }
      },
      beforeAddFile: async () => {
        // Since we do not use Axios / TresPortalBaseAxiosHelper, we must ensure the tokens are still valid by forcing a refresh.
        const authApi = new AuthenticationApi()
        const tokens = this.$store.getters['Auth/getAuthorizationTokens']
        const { data } = await authApi.authenticationRefreshAccessToken(tokens)
        this.$store.dispatch('Auth/refresh', data)
        return true
      },
      beforeRemoveFile: () => {
        this.inputField.value = ''
        this.inputField.errors = []
        return true
      },
      acceptedFileTypes: [
        'application/pdf',
        'image/png',
        'image/tiff',
        'image/jpeg',
        'image/bmp'],
      process: (error: FilePondErrorDescription, file: FilePondFile) => {
        this.inputField.value = file.serverId
        this.inputField.errors = []
        if (error) {
          if (error.body) {
            this.inputField.errors.push(error.body)
          } else {
            this.inputField.errors.push('Dit bestand wordt niet geaccepteerd.')
          }
        }
      }
    }
  }

  constructor () {
    super()
    this.interacted = false

    if (this.inputField.type === 'date' && typeof this.inputField.value === 'string') {
      this.inputField.value = this.valueByType
    }
  }

  get fileLabel () {
    if (typeof this.inputField.value === 'string') {
      if (this.inputField.value === '') {
        return 'Selecteer je bestand'
      }
      const pathArray = this.inputField.value?.split(/[\\/]+/)
      if (!pathArray) {
        return this.inputField.value
      } else {
        return pathArray[pathArray.length - 1]
      }
    }
    return ''
  }

  get valueByType () {
    if (this.inputField.value === '') {
      return ''
    }
    if (this.inputField.type === 'date' && typeof this.inputField.value === 'string') {
      try {
        const date = new Date(Date.parse(this.inputField.value))
        return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`
      } catch {
        return this.inputField.value
      }
    } else {
      return this.inputField.value
    }
  }

  get getPasswordScore () {
    switch (this.passwordScore) {
      case 0: return 'Zeer zwak'
      case 1: return 'Zwak'
      case 2: return 'Matig'
      case 3: return 'Sterk'
      case 4: return 'Zeer sterk'
      default: return ''
    }
  }

  get passwordInputType () {
    return this.showPassword ? 'text' : 'password'
  }

  public validate () {
    this.interacted = true
    this.overrideErrorDisplay = false
    this.inputField.errors = []

    if (this.inputField.required && (this.inputField.value === '' || !this.inputField.value)) {
      if (this.inputField.customErrorMessage) {
        this.inputField.errors.push(this.inputField.customErrorMessage)
        return false
      }

      this.inputField.errors.push(`Oops, je bent je ${this.inputField.placeholder.toLowerCase()} vergeten in te vullen.`)
      return false
    }

    if (this.inputField.type === 'email') {
      const valid = this.isValidEmailaddress(this.inputField.value)
      if (!valid) {
        this.inputField.errors.push('Oops, je hebt geen geldig e-mailadres ingevuld')
        return false
      }
    }

    if (this.inputField.type === 'newPassword') {
      let valid = true
      if (!this.inputField.value.match(/[a-z]/) || !this.inputField.value.match(/[A-Z]/) || !this.inputField.value.match(/\d/)) {
        this.inputField.errors.push('Je nieuwe wachtwoord moet in ieder geval 1 hoofdletter, 1 kleine letter en een cijfer bevatten')
        valid = false
      }

      if (this.inputField.value.length < 12) {
        this.inputField.errors.push('Je nieuwe wachtwoord moet minstens 12 tekens bevatten')
        valid = false
      }
      return valid
    }

    return true
  }

  public togglePasswordDisplay () {
    this.showPassword = !this.showPassword
  }

  public showPasswordScore (score: number) {
    this.passwordScore = score
  }

  private isValidEmailaddress (input: string) {
    const re = RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    return re.test(input)
  }

  private removeErrorDisplay () {
    this.overrideErrorDisplay = true
  }
}

interface InputFieldOption {
  label: string;
  value: string;
  image?: string;
}
type inputFieldOption = InputFieldOption[];

export interface InputField {
  name: string;
  type: string;
  value: string;
  placeholder: string;
  required: boolean;
  min?: number | string;
  max?: number | string;
  step?: number;
  info?: string;
  inline?: boolean;
  icon?: string;
  options?: inputFieldOption;
  readonly?: boolean;
  errors: string[];
  hidden?: boolean;
  files?: string[];
  disabled?: boolean;
  customErrorMessage?: string;
  infoColor?: 'black';
  inputExplanationParagraph?: string;
}

export type FormInputInstance = InstanceType<typeof FormInput>
