import ChangePassword from '@/components/Account/ChangePassword.vue'

const routes = [
  {
    path: '/account/change-password/',
    name: 'changePassword',
    component: ChangePassword,
    meta: {
      page: 4
    }
  }
]

export default routes
