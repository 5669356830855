import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VueGtag from 'vue-gtag'

// Setup Axios
import axios from '@/config/axios'

// Register global Vue components
import { registerDefaultComponents } from '@/config/globalComponents'

// Initialize FontAwesome icons
import { initializeFontAwesome } from '@/config/FontAwesome'

Vue.config.productionTip = false
axios.setup()
registerDefaultComponents()
initializeFontAwesome()

if (process.env.VUE_APP_STAGE === 'test') {
  Vue.use(VueGtag, {
    config: { id: 'UA-63201910-4' }
  }, router)
} else if (process.env.VUE_APP_STAGE === 'staging') {
  Vue.use(VueGtag, {
    config: { id: 'UA-63201910-5' }
  }, router)
} else {
  Vue.use(VueGtag, {
    config: { id: 'UA-63201910-2' }
  }, router)
}

new Vue({
  store,
  router,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  render: (h) => h(App)
}).$mount('#app')
