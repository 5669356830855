<template>
  <div class="alert">
    <div class="alert__icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M8.27,3L3,8.27V15.73L8.27,21H15.73C17.5,19.24 21,15.73 21,15.73V8.27L15.73,3M9.1,5H14.9L19,9.1V14.9L14.9,19H9.1L5,14.9V9.1M11,15H13V17H11V15M11,7H13V13H11V7"></path></svg>
    </div>
    <div class="alert__message">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alertError'
}
</script>

<style lang="scss" scoped>
  .alert {

    align-items: center;
    background: #FFEBEB;
    border-left: 4px solid #ff5252;
    border-radius: 4px;
    color: #ff5252;
    display: flex;
    margin: 20px 0;
    padding: 16px;
    text-align: left;

    &__icon {
      fill: currentColor;
      height: 32px;
      margin-right: 10px;
      width: 32px;
      flex: 0 0 32px;
    }
  }
</style>
