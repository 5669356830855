import Vue from 'vue'
import Component from 'vue-class-component'

enum states {
  INITIAL,
  LOADING,
  ERROR,
  COMPLETE,
  LOADED,
}

@Component
export class State extends Vue {
  public states = states;
  public state = states.INITIAL;

  public currentStateIs (state: states): boolean {
    return this.state === state
  }

  public setState (state: states): void {
    this.state = state
  }
}
