import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { AuthorizedResultDto } from '@/api'
import { AuthState } from './types'

const actions: ActionTree<AuthState, RootState> = {
  logIn ({ commit }, payload: AuthState): void {
    commit('SET_CURRENT_USER', payload)
  },
  logOut ({ commit }): void {
    commit('SET_CURRENT_USER', null)
  },
  refresh ({ commit, state }, payload: AuthorizedResultDto): void {
    commit('SET_CURRENT_USER', { ...state, authorization: payload })
  }
}

export default actions
