

























import Component, { mixins } from 'vue-class-component'

import { AccountApi } from '@/api'
import { State } from '@/mixins/state'

import Toolbar from '@/components/Layout/Toolbar.vue'
import Content from '@/components/Content.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'

interface InputFields {
  password: InputField;
  newPassword: InputField;
  newPasswordRepeat: InputField;
}

@Component({
  components: {
    Content,
    Toolbar,
    FormButton,
    FormInput
  }
})
export default class AccountChangePassword extends mixins(State) {
  inputFields: InputFields = {
    password: {
      name: 'password',
      value: '',
      required: true,
      type: 'password',
      placeholder: 'Huidig wachtwoord',
      errors: [],
      inline: true,
      icon: 'unlock'
    },

    newPassword: {
      name: 'newPassword',
      value: '',
      required: true,
      type: 'newPassword',
      placeholder: 'Nieuw wachtwoord',
      errors: [],
      inline: true,
      icon: 'unlock'
    },

    newPasswordRepeat: {
      name: 'newPasswordRepeat',
      value: '',
      required: true,
      type: 'password',
      placeholder: 'Wachtwoord (herhalen)',
      errors: [],
      inline: true,
      icon: 'unlock'
    }
  }

  errors = [
    {
      name: 'password',
      errors: [] as string[]
    },
    {
      name: 'passwordRepeat',
      errors: [] as string[]
    }
  ]

  private resetPassword () {
    if (!this.validateInput()) {
      return
    }

    this.setState(this.states.LOADING)
    const api = new AccountApi()

    api.accountUpdatePassword({
      password: this.inputFields.password.value,
      newPassword: this.inputFields.newPassword.value
    })
      .then(() => {
        this.setState(this.states.COMPLETE)
      })
      .catch((e) => {
        this.setErrors(e.response.data.errors)
        this.setState(this.states.ERROR)
      })
  }

  private validateInput () {
    let valid = true

    this.inputFields.newPasswordRepeat.errors = []
    if (this.inputFields.newPasswordRepeat.value !== this.inputFields.newPassword.value) {
      valid = false
      this.inputFields.newPasswordRepeat.errors.push('De wachtwoorden komen niet overeen')
    }

    valid = (this.$refs.newPassword as FormInput).validate() && valid

    return valid
  }

  private getErrorObject (name: string) {
    return this.errors.find(item => item.name === name) || {
      name: name,
      errors: []
    }
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([errorKey, errorValue]) => {
      Object.entries(this.inputFields).forEach(([fieldKey, fieldValue]) => {
        if (errorKey.toLowerCase() === fieldKey.toLowerCase()) {
          fieldValue.errors = errorValue
        }
      })
    })
  }

  public back () {
    this.$router.push({ name: 'dashboard' })
  }
}
