import { ActionTree } from 'vuex'
import { RootState } from '@/store/types'
import { CancelState, CancelPayload } from './types'

const actions: ActionTree<CancelState, RootState> = {
  setCancellation ({ commit }, payload: CancelPayload): void {
    commit('SET_CANCELLATION', payload)
  },
  setCovidSuspensionDate ({ commit }, payload: string): void {
    commit('SET_COVID_SUSPENSION_DATE', payload)
  }
}

export default actions
