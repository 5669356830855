










import Vue from 'vue'
import Component from 'vue-class-component'
import { AuthenticationApi } from '@/api'

import Router from '@/router'
import Store from '@/store'

import Content from '@/components/Content.vue'
import Logo from '@/components/Layout/Logo.vue'

@Component({
  components: {
    Content,
    Logo
  }
})
export default class AuthLogout extends Vue {
  mounted () {
    this.logout()
  }

  private localLogoutExecuted = false

  private localLogout () {
    this.localLogoutExecuted = true

    let trigger = 'logout'
    if (this.$route.query.trigger && this.$route.query.trigger === 'timeout') {
      trigger = this.$route.query.trigger
    }

    // TODO reset saved cancellationstate.
    Store.dispatch('Auth/logOut').then(() => {
      Router.push({
        path: '/',
        query: {
          trigger: trigger
        }
      })
    })
  }

  private async logout () {
    // In case the logout API call fails, we still want to remove the locally stored tokens and return the user to the login page.
    // The API call fails in case the refresh token is not valid anymore. Then the finally-block is never called, because the TresPortalBaseAxiosHelper removes the callback.
    const localTimer = setTimeout(() => {
      this.localLogout()
    }, 2000)

    const api = new AuthenticationApi()

    try {
      const authorizationTokens =
        Store.getters['Auth/getAuthorizationTokens']

      if (authorizationTokens) {
        await api.authenticationLogout({
          refreshToken: authorizationTokens.refreshToken
        })
      }
    } finally {
      if (!this.localLogoutExecuted) {
        clearTimeout(localTimer)
        this.localLogout()
      }
    }
  }
}
