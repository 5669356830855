














































import Component, { mixins } from 'vue-class-component'

import { State } from '@/mixins/state'
import Store from '@/store'
import Router from '@/router'

import { AccountApi, AuthenticationApi, MaintenanceApi } from '@/api'
import { AuthState } from '@/store/modules/auth/types'

import Content from '@/components/Content.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import Logo from '@/components/Layout/Logo.vue'

@Component({
  components: {
    Content,
    Logo,
    FormButton,
    FormInput
  }
})
export default class AuthLogin extends mixins(State) {
  emailAddress: InputField = {
    name: 'emailAddress',
    value: '',
    required: true,
    type: 'email',
    placeholder: 'E-mailadres',
    errors: [],
    inline: true,
    icon: 'envelope'
  }

  password: InputField = {
    name: 'password',
    value: '',
    required: true,
    type: 'password',
    placeholder: 'Wachtwoord',
    errors: [],
    inline: true,
    icon: 'unlock'
  }

  maintenanceApi = new MaintenanceApi()
  announcementTitle = ''
  announcementContent = ''
  announcementSnackbar = false

  mounted () {
    this.ping()
    this.getAnnouncement()
  }

  private async ping () {
    // This triggers the under construction page.
    await this.maintenanceApi.maintenancePing()
  }

  private async getAnnouncement () {
    try {
      const { data } = await this.maintenanceApi.maintenanceGetAnnouncementText()
      if (data.content) {
        this.announcementTitle = data.title || ''
        this.announcementContent = data.content || ''
        this.announcementSnackbar = true
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}

    // Show default text.
    if (this.announcementSnackbar === false) {
      this.announcementTitle = ''
      this.announcementContent = 'Goed om te weten: het kan sporadisch voorkomen dat je na het inloggen geen gegevens ziet. Soms hebben we nét iets langer nodig om de data op te halen. Is dit het geval, probeer het dan over 5 minuten nog een keer en doe ondertussen een aantal fijne stretch oefeningen. You got this!'
      this.announcementSnackbar = true
    }
  }

  public async authenticate () {
    if (!this.validateInput()) {
      return
    }

    this.setState(this.states.LOADING)

    try {
      const api = new AuthenticationApi()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await api.authenticationLoginWithCredentials({
        emailAddress: this.emailAddress.value,
        password: this.password.value
      })
      let authState: AuthState = {
        user: {
          email: this.emailAddress.value,
          accountId: 0
        },
        authorization: response.data
      }
      if (response.response && response.response.status === 503) {
        // In case of 503, redirect to construction page
        Router.push({ name: 'underconstruction' })
      } else {
        Store.dispatch('Auth/logIn', authState).then(() => {
          Router.push({ path: '/users' })
        })
        const accountApi = new AccountApi()
        const accountData = await accountApi.accountGetProfile()

        authState = {
          user: {
            email: accountData.data.emailAddress || '',
            accountId: accountData.data.accountId
          },
          authorization: response.data
        }
        // TODO reset saved cancellationstate.
        Store.dispatch('Auth/logIn', authState)
      }
    } catch (err) {
      this.setState(this.states.ERROR)
      if (err.response && err.response.status === 409) {
        // 2FA - not implemented
      } else {
        // Error
      }
    }
  }

  private validateInput () {
    let valid = true
    valid = (this.$refs.password as FormInput).validate() && valid
    valid = (this.$refs.emailAddress as FormInput).validate() && valid

    return valid
  }
}
