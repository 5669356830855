import { AxiosRequestConfig, AxiosInstance, AxiosPromise } from 'axios'
import Router from '@/router'
class TresPortalBaseAxiosHelper {
  private axios: AxiosInstance;

  private pendingRequests: { (): void }[];

  private accessToken: string;

  private isRefreshing: boolean;

  private refreshFunction: { (): Promise<string> };

  constructor (axios: AxiosInstance, refreshFunction: { (): Promise<string> }) {
    this.axios = axios
    this.pendingRequests = []
    this.isRefreshing = false
    this.accessToken = ''
    this.refreshFunction = refreshFunction

    this.axios.defaults.headers.common['accept-language'] = 'nl-NL'
  }

  public setup () {
    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const {
          config,
          response: { status }
        } = error
        const originalRequest = config
        if (status === 503) {
          // In case of 503, redirect to construction page
          Router.push({ name: 'underconstruction' })
          return Promise.resolve(error)
        }
        if (status !== 401) return Promise.reject(error)
        if (!this.isRefreshing) {
          this.isRefreshing = true
          this.refreshFunction().then((token) => {
            this.isRefreshing = false
            this.accessToken = token

            if (token) {
              this.completePendingRequests()
            } else {
              this.pendingRequests = []
            }
          })
        }
        return this.addPendingRequest(originalRequest)
      }
    )
  }

  private addPendingRequest (
    request: AxiosRequestConfig
  ): Promise<AxiosPromise> {
    return new Promise((resolve) => {
      this.pendingRequests.push(() => {
        const newRequest = request
        newRequest.headers.Authorization = `bearer ${this.accessToken}`
        resolve(this.axios(newRequest))
      })
    })
  }

  private completePendingRequests (): void {
    this.pendingRequests = this.pendingRequests.filter((callback) =>
      callback()
    )
  }
}

export default TresPortalBaseAxiosHelper
