import Login from '@/components/Auth/Login.vue'
import RequestPassword from '@/components/Auth/RequestPassword.vue'
import ResetPassword from '@/components/Auth/ResetPassword.vue'
import ActivateAccount from '@/components/Auth/ActivateAccount.vue'
import LoginWithToken from '@/components/Auth/Token.vue'
import Logout from '@/components/Auth/Logout.vue'

const routes = [
  {
    path: '',
    name: 'login',
    component: Login,
    meta: {
      page: 1
    }
  },
  {
    path: '/auth/request-password/:origin?',
    name: 'requestPassword',
    component: RequestPassword
  },
  {
    path: '/auth/reset-password/:token?',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/auth/activate/:token?',
    name: 'activateAccount',
    component: ActivateAccount
  },
  {
    path: '/auth/token/:token',
    name: 'loginWithToken',
    component: LoginWithToken
  },
  {
    path: '/auth/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/auth/underconstruction',
    name: 'underconstruction',
    component: () => import('@/views/UnderConstruction.vue')
  }
]

export default routes
