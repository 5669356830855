import { GetterTree } from 'vuex'
import { RootState } from '@/store/types'
import { AuthorizedResultDto } from '@/api/'
import { AuthState } from './types'

const getters: GetterTree<AuthState, RootState> = {
  isAuthenticated (state): boolean {
    return (
      state.authorization?.accessToken !== undefined &&
      state.authorization?.refreshToken !== undefined
    )
  },
  getAccountId (state): number {
    return (state.user?.accountId || 0)
  },
  getAuthorizationTokens (state): AuthorizedResultDto | null {
    return state.authorization
  }
}

export default getters
