<template>
  <div class="loader">
    <div class="loader__svg">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 70 24">
        <g fill="none" fill-rule="evenodd">
          <g transform="translate(1 1)" class="loader-logo--1">
            <path fill="#00A0D7" d="M17.6 22H0L3 .3h17.5z"></path>
          </g>
          <g transform="translate(24 1)" class="loader-logo--2">
            <path fill="#CAD008" d="M18 22H.4L3.4.3h17.5z"></path>
          </g>
          <g transform="translate(47 1)" class="loader-logo--3">
            <path fill="#E84F23" d="M18.4 22H.8L3.8.3h17.5z"></path>
          </g>
        </g>
      </svg>
    </div>
    <div class="loader__text">
      <h6>Aan het laden ...</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: 'loader'
}
</script>

<style lang="scss" scoped>

.loader {
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 60px;

  &__svg {
    margin: 0 auto 0;
    max-width: 100px;
  }

  &__text {
    margin: 0 auto;
    text-align: center;

    h6 {
      margin-bottom: 0;
    }
  }
}

.loader-logo {
  &--1,
  &--2,
  &--3 {
    animation: pulse 2s infinite;
  }

  &--1 {
    animation-delay: 0;
  }

  &--2 {
    animation-delay: 150ms;
  }

  &--3 {
    animation-delay: 300ms;
  }
}

@keyframes pulse {
  0% {
    // transform: scale(0);
    opacity: 0;
  }

  50% {
    // transform: scale(1);
    opacity: 1;
  }

  100% {
    // transform: scale(0);
    opacity: 0;
  }
}

</style>
