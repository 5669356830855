import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export function registerDefaultComponents () {
  const requireComponent = require.context(
    // The relative path of the components folder
    '../components/Global',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /[A-Z]\w+\.(vue|js)$/
  )

  requireComponent.keys().forEach((fileName) => {
    // Get component config
    const componentConfig = requireComponent(fileName)

    if (!fileName) {
      fileName = ''
    }

    if (fileName) {
      // Get PascalCase name of component
      const path = fileName.split('/')
      let name = fileName
      if (path) {
        name = path.pop() || ''
      }
      const componentName = upperFirst(camelCase(name.replace(/\.\w+$/, '')))

      // Register component globally
      Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
      )
    }
  })
}
