




import Vue from 'vue'
import Component from 'vue-class-component'

@Component({})
export default class AutoLogout extends Vue {
  userActivityTimeout: number | null = null;
  userActivityThrottlerTimeout: number | null = null;
  listeners = ['mousedown', 'scroll', 'keydown', 'resize'] // for performance reasons, we won't track mousemove

  mounted () {
    this.activateActivityTracker()
  }

  private activateActivityTracker () {
    this.listeners.forEach(listener => {
      window.addEventListener(listener, this.resetUserActivityTimeout)
    })

    window.addEventListener('beforeunload', this.deactivateActivityTracker)
  }

  private deactivateActivityTracker () {
    this.listeners.forEach(listener => {
      window.removeEventListener(listener, this.resetUserActivityTimeout)
    })
    window.removeEventListener('beforeunload', this.deactivateActivityTracker)
  }

  private resetUserActivityTimeout () {
    if (this.userActivityTimeout) {
      clearTimeout(this.userActivityTimeout)
    }

    this.userActivityTimeout = setTimeout(() => {
      this.inactiveUserAction()
    }, 1000 * 60 * 10) // 10 minutes
  }

  private inactiveUserAction () {
    const isAuthenticated = this.$store.getters['Auth/isAuthenticated']
    if (isAuthenticated) {
      this.$router.push({
        path: '/auth/logout',
        query: {
          trigger: 'timeout'
        }
      })
    }
  }
}
