import { MutationTree } from 'vuex'
import { CancelPayload, CancelState } from './types'

const mutations: MutationTree<CancelState> = {
  SET_CANCELLATION (state, payload: CancelPayload) {
    state.cancellation.cancellationDate = payload.cancellationDate
    state.cancellation.cancelReasonId = payload.cancelReasonId
    state.cancellation.cancelUploadGuid = payload.cancelUploadGuid
    state.cancellation.cancelExplanation = payload.cancelExplanation
  },
  SET_COVID_SUSPENSION_DATE (state, payload: string) {
    state.covidSuspensionDate = payload
  }
}

export default mutations
