import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate'

import { Auth } from './modules/auth/index'
import { Cancel } from './modules/cancel-subscription/index'
import { RootState } from '@/store/types'
import { User } from '@/types'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  state: {
    version: '0.0.3',
    currentUser: null,
    hasMultipleUsers: false,
    requestPasswordOrigin: '',
    distinctSportLocationNames: []
  },
  mutations: {
    setCurrentUser (state: RootState, user: User) {
      state.currentUser = user
    },

    setMultipleUsers (state: RootState, hasMultipleUsers: boolean) {
      state.hasMultipleUsers = hasMultipleUsers
    },

    setRequestPasswordOrigin (state: RootState, origin: string) {
      state.requestPasswordOrigin = origin
    },

    setDistinctSportLocationNames (state: RootState, distinctSportLocationNames: Array<string>) {
      state.distinctSportLocationNames = distinctSportLocationNames
    }
  },
  actions: {
  },
  modules: {
    Auth,
    cancel: Cancel
  },
  plugins: [createPersistedState({
    key: 'bfnl.employee'
  })]
})
