<template>
  <div class="alert">
    <div class="alert__icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M11,9H13V7H11M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M11,17H13V11H11V17Z"></path></svg>
    </div>
    <div class="alert__message">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alertInfo'
}
</script>

<style lang="scss" scoped>
  .alert {

    align-items: center;
    background: #e5f3fe;
    border-left: 4px solid #2196f3;
    border-radius: 4px;
    color: #2196f3;
    display: flex;
    margin: 20px 0;
    padding: 16px;
    text-align: left;

    &__icon {
      fill: currentColor;
      height: 32px;
      margin-right: 10px;
      width: 32px;
      flex: 0 0 32px;
    }
  }
</style>
