import axios from 'axios'
import store from '@/store/index'
export default {
  setup () {
    const user = store.getters['auth/getAuth']
    if (user && user.accessToken) {
      this.setHeader(user.accessToken)
      this.createAxiosResponseInterceptor()
    }
  },
  setHeader (token: string) {
    axios.defaults.headers.common = {
      Authorization: `bearer ${token}`
    }
  },
  reset () {
    delete axios.defaults.headers.common.Authorization
  },
  createAxiosResponseInterceptor () {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status !== 401) {
          return Promise.reject(error)
        }

        axios.interceptors.response.eject(interceptor)

        store
          .dispatch('auth/refresh')
          .then(() => {
            const token = store.getters['auth/getAuth'].accessToken
            error.response.config.headers.Authorization = `bearer ${token}`
            return axios(error.response.config)
          })
          .finally(() => {
            this.createAxiosResponseInterceptor()
          })
      }
    )
  }
}
