
































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Store from '@/store'
import axios from 'axios'

import vue100vh from 'vue-100vh'
import { Route } from 'vue-router'
import { AuthenticationApi } from './api'
import TresPortalBaseAxiosHelper from '@/util/axios'

import AutoLogout from '@/components/AutoLogout.vue'

@Component({
  components: {
    vue100vh,
    AutoLogout
  }
})
export default class App extends Vue {
  public transitionName: string;

  constructor () {
    super()

    this.transitionName = ''

    const api = new AuthenticationApi()

    const refreshAccessToken = async () => {
      try {
        const authorizationTokens =
          Store.getters['Auth/getAuthorizationTokens']
        const { data } = await api.authenticationRefreshAccessToken(
          authorizationTokens
        )
        Store.dispatch('Auth/refresh', data)
        return data?.accessToken || ''
      } catch {
        if (this.$router.currentRoute.path !== '/auth/logout') {
          this.$router.push({
            path: '/auth/logout',
            query: {
              trigger: 'timeout'
            }
          })
        }
        return ''
      }
    }

    const axiosHelper = new TresPortalBaseAxiosHelper(
      axios,
      refreshAccessToken
    )
    axiosHelper.setup()
  }

  mounted () {
    this.$root.$on('scrollToTop', () => {
      const app = document.querySelector('.app')
      if (app) {
        app.scrollTo({
          top: 0
        })
      }
    })
  }

  get isAuthenticatedUser () {
    return Store.getters['Auth/isAuthenticated']
  }

  @Watch('$route')
  public onRouteChange (to: Route, from: Route) {
    this.transitionName = from.meta?.skipTransition ? '' : (to.meta?.page > from.meta?.page ? 'next' : 'prev')
  }
}
