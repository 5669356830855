<template>
  <div class="alert">
    <div class="alert__icon">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-hidden="true" class="v-icon__svg"><path d="M12,2C17.52,2 22,6.48 22,12C22,17.52 17.52,22 12,22C6.48,22 2,17.52 2,12C2,6.48 6.48,2 12,2M11,16.5L18,9.5L16.59,8.09L11,13.67L7.91,10.59L6.5,12L11,16.5Z"></path></svg>
    </div>
    <div class="alert__message">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'alertInfo'
}
</script>

<style lang="scss" scoped>
  .alert {

    align-items: center;
    background: #EAF6EA;
    border-left: 4px solid #4caf50;
    border-radius: 4px;
    color: #4caf50;
    display: flex;
    margin: 20px 0;
    padding: 16px;
    text-align: left;

    &__icon {
      fill: currentColor;
      height: 32px;
      margin-right: 10px;
      width: 32px;
      flex: 0 0 32px;
    }
  }
</style>
