












import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class Toolbar extends Vue {
  @Prop({ default: false }) public readonly hasCustomCallback!: boolean;
}
