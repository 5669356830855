








import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Component
export default class FormButton extends Vue {
  @Prop({ default: false }) public readonly loading!: boolean;
  @Prop({ default: false }) public readonly disabled!: boolean;
  @PropSync('caption', { type: String }) public text!: string;
}
