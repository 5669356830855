import { Module } from 'vuex'
import { RootState } from '@/store/types'
import { AuthState } from './types'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export const state: AuthState = {
  user: null,
  authorization: null
}

const namespaced = true

export const Auth: Module<AuthState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations
}
