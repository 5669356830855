




































import Component, { mixins } from 'vue-class-component'

import { AuthenticationApi } from '@/api'
import { State } from '@/mixins/state'

import Content from '@/components/Content.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import Logo from '@/components/Layout/Logo.vue'

@Component({
  components: {
    Content,
    Logo,
    FormButton,
    FormInput
  }
})
export default class AuthResetPassword extends mixins(State) {
  password: InputField = {
    name: 'password',
    value: '',
    required: true,
    type: 'newPassword',
    placeholder: 'Wachtwoord',
    errors: [],
    inline: true,
    icon: 'unlock'
  }

  passwordRepeat: InputField = {
    name: 'passwordRepeat',
    value: '',
    required: true,
    type: 'password',
    placeholder: 'Wachtwoord (herhalen)',
    errors: [],
    inline: true,
    icon: 'unlock'
  }

  token = ''
  tokenValid = false
  returnUrl = ''

  errors = [
    {
      name: 'password',
      errors: [] as string[]
    },
    {
      name: 'passwordRepeat',
      errors: [] as string[]
    }
  ]

  mounted () {
    const origin = this.$store.state.requestPasswordOrigin
    if (origin === 'registration-flow') {
      this.returnUrl = process.env.VUE_APP_REGISTRATION_FLOW_STEP1
    }

    this.token = this.$route.params.token
    if (this.token) {
      const api = new AuthenticationApi()
      api.authenticationIsPasswordResetTokenValid({
        passwordResetToken: this.token
      })
        .then(() => {
          this.tokenValid = true
          this.setState(this.states.INITIAL)
        })
        .catch(() => {
          this.tokenValid = false
          this.setState(this.states.ERROR)
        })
    } else {
      this.setState(this.states.ERROR)
    }
  }

  private resetPassword () {
    if (!this.validateInput() || !this.tokenValid) {
      return
    }

    this.setState(this.states.LOADING)
    const api = new AuthenticationApi()

    api.authenticationResetPassword({
      passwordResetToken: this.token,
      password: this.password.value
    })
      .then(() => {
        this.setState(this.states.COMPLETE)
      })
      .catch((e) => {
        this.setErrors(e.response.data.errors)
        this.setState(this.states.ERROR)
      })
  }

  private validateInput () {
    let valid = true

    this.passwordRepeat.errors = []
    if (this.passwordRepeat.value !== this.password.value) {
      valid = false
      this.passwordRepeat.errors.push('De wachtwoorden komen niet overeen')
    }

    valid = (this.$refs.password as FormInput).validate() && valid

    return valid
  }

  private getErrorObject (name: string) {
    return this.errors.find(item => item.name === name) || {
      name: name,
      errors: []
    }
  }

  public setErrors (errors: []) {
    Object.entries(errors).forEach(([key, val]) => {
      this.getErrorObject(key.toLowerCase()).errors = val
    })
  }
}
