








import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Component
export default class Content extends Vue {
  @PropSync('center', { type: Boolean }) public centered!: boolean;
}
