<template>
  <router-view></router-view>
</template>

<script>
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class AuthView extends Vue {
}
</script>
