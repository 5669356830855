import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUser, faEnvelope, faUnlock, faPencil, faExternalLink, faEye, faEyeSlash, faCheckSquare, faSquare, faEnvelopeOpen } from '@fortawesome/pro-light-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons'

export function initializeFontAwesome () {
  library.add(faUser, faArrowLeft, faEnvelope, faUnlock, faPencil, faExternalLink, faEye, faEyeSlash, faSquare, faCheckSquare, faEnvelopeOpen, faWhatsapp)
  Vue.component('font-awesome-icon', FontAwesomeIcon)
}
