

















import Component, { mixins } from 'vue-class-component'

import { State } from '@/mixins/state'
import Store from '@/store'
import Router from '@/router'

import { AccountApi, AuthenticationApi } from '@/api'
import { AuthState } from '@/store/modules/auth/types'

import Content from '@/components/Content.vue'
import Logo from '@/components/Layout/Logo.vue'

@Component({
  components: {
    Content,
    Logo
  }
})
export default class AuthLogin extends mixins(State) {
  public async authenticate () {
    this.setState(this.states.LOADING)

    const api = new AuthenticationApi()

    // When loggin in by token, first logout any currently logged in user.
    try {
      const authorizationTokens =
        Store.getters['Auth/getAuthorizationTokens']

      if (authorizationTokens) {
        await api.authenticationLogout({
          refreshToken: authorizationTokens.refreshToken
        })
      }
    } finally {
      Store.dispatch('Auth/logOut')
      this.$store.commit('setCurrentUser', null)
    }

    try {
      // Login by token.
      const { data } = await api.authenticationLoginWithToken({
        loginToken: this.$route.params.token
      })

      // Save token, so we can do the authenicated call to get users profile.
      const authState: AuthState = {
        user: {
          email: '',
          accountId: 0
        },
        authorization: data
      }
      Store.dispatch('Auth/logIn', authState)
      // TODO reset saved cancellationstate.

      const accountApi = new AccountApi()
      const accountData = await accountApi.accountGetProfile()

      // Save user.
      authState.user = {
        email: accountData.data.emailAddress || '',
        accountId: accountData.data.accountId
      }

      Store.dispatch('Auth/logIn', authState).then(() => {
        // Remove cachebuster querystring from browser url.
        window.history.replaceState({}, document.title, window.location.origin + window.location.pathname + '#')
        Router.push({ path: '/users' })
      })
    } catch (err) {
      this.setState(this.states.ERROR)
      if (err.response && err.response.status === 409) {
        // 2FA - not implemented
      } else {
        // Error
      }
    }
  }

  mounted () {
    this.authenticate()
  }
}
