/* tslint:disable */
/* eslint-disable */
/**
 * Bfnl Employee Portal API
 * Bfnl Employee Portal API.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@tres.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Activate an account.
 * @export
 * @interface ActivateAccountRequest
 */
export interface ActivateAccountRequest {
    /**
     * Activation token.
     * @type {string}
     * @memberof ActivateAccountRequest
     */
    activationToken: string;
    /**
     * The new password for the account.
     * @type {string}
     * @memberof ActivateAccountRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface AddCampaignTipAndGenerateLotteryNumbersCommand
 */
export interface AddCampaignTipAndGenerateLotteryNumbersCommand {
    /**
     * Registration id.
     * @type {number}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommand
     */
    registrationId: number;
    /**
     * Name of employee.
     * @type {string}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommand
     */
    name: string;
    /**
     * E-mailaddress of employee.
     * @type {string}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommand
     */
    email: string;
    /**
     * Name of colleague
     * @type {string}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommand
     */
    colleagueName: string;
    /**
     * E-mailaddress of colleague.
     * @type {string}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommand
     */
    colleagueEmail: string;
    /**
     * Approve campaign.
     * @type {boolean}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommand
     */
    approveCampaign: boolean;
}
/**
 * 
 * @export
 * @interface AddCampaignTipAndGenerateLotteryNumbersCommandAllOf
 */
export interface AddCampaignTipAndGenerateLotteryNumbersCommandAllOf {
    /**
     * Registration id.
     * @type {number}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommandAllOf
     */
    registrationId: number;
    /**
     * Name of employee.
     * @type {string}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommandAllOf
     */
    name: string;
    /**
     * E-mailaddress of employee.
     * @type {string}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommandAllOf
     */
    email: string;
    /**
     * Name of colleague
     * @type {string}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommandAllOf
     */
    colleagueName: string;
    /**
     * E-mailaddress of colleague.
     * @type {string}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommandAllOf
     */
    colleagueEmail: string;
    /**
     * Approve campaign.
     * @type {boolean}
     * @memberof AddCampaignTipAndGenerateLotteryNumbersCommandAllOf
     */
    approveCampaign: boolean;
}
/**
 * Article of subscription.
 * @export
 * @interface ArticleDto
 */
export interface ArticleDto {
    /**
     * Article code.
     * @type {string}
     * @memberof ArticleDto
     */
    code?: string | null;
    /**
     * Description.
     * @type {string}
     * @memberof ArticleDto
     */
    description?: string | null;
    /**
     * Explanation.
     * @type {string}
     * @memberof ArticleDto
     */
    explanation?: string | null;
    /**
     * Is this article an intake?
     * @type {boolean}
     * @memberof ArticleDto
     */
    isIntake?: boolean;
    /**
     * Duration in months. Null for intake.
     * @type {number}
     * @memberof ArticleDto
     */
    durationInMonths?: number | null;
    /**
     * Price per month of article. For intake, this is a one-time price.
     * @type {number}
     * @memberof ArticleDto
     */
    pricePerMonth?: number | null;
}
/**
 * Article.
 * @export
 * @interface ArticleDto2
 */
export interface ArticleDto2 {
    /**
     * Article code.
     * @type {string}
     * @memberof ArticleDto2
     */
    code?: string | null;
    /**
     * Description.
     * @type {string}
     * @memberof ArticleDto2
     */
    description?: string | null;
    /**
     * Explanation.
     * @type {string}
     * @memberof ArticleDto2
     */
    explanation?: string | null;
    /**
     * Duration in months.
     * @type {number}
     * @memberof ArticleDto2
     */
    durationInMonths?: number;
    /**
     * Price per month of article.
     * @type {number}
     * @memberof ArticleDto2
     */
    pricePerMonth?: number;
}
/**
 * Information returned after an login attempt.
 * @export
 * @interface AuthorizedResultDto
 */
export interface AuthorizedResultDto {
    /**
     * The JWT token to authenticate future calls to the API. Only present after a successful login.
     * @type {string}
     * @memberof AuthorizedResultDto
     */
    accessToken?: string | null;
    /**
     * The token to refresh an expired access token with. Only present after a successful login.
     * @type {string}
     * @memberof AuthorizedResultDto
     */
    refreshToken?: string | null;
}
/**
 * Cancel reason.
 * @export
 * @interface CancelReasonDto
 */
export interface CancelReasonDto {
    /**
     * Id.
     * @type {CancelReasonId}
     * @memberof CancelReasonDto
     */
    cancelReasonId?: CancelReasonId;
    /**
     * Reason.
     * @type {string}
     * @memberof CancelReasonDto
     */
    reason?: string | null;
    /**
     * Is upload required?
     * @type {boolean}
     * @memberof CancelReasonDto
     */
    uploadRequired?: boolean;
    /**
     * Description of upload.
     * @type {string}
     * @memberof CancelReasonDto
     */
    uploadDescription?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CancelReasonId {
    GoalAchieved = 'GoalAchieved',
    LeavingWork = 'LeavingWork',
    LittleUse = 'LittleUse',
    Moving = 'Moving',
    MedicalReason = 'MedicalReason',
    ChangeSportLocation = 'ChangeSportLocation',
    Other = 'Other',
    CancelledAtSportLocation = 'CancelledAtSportLocation',
    UpgradeOrDownGrade = 'UpgradeOrDownGrade',
    SubscriptionEnd = 'SubscriptionEnd',
    PbpEnd = 'PbpEnd',
    ProEnd = 'ProEnd',
    FcEnd = 'FcEnd'
}

/**
 * 
 * @export
 * @interface CancelSubscriptionCommand
 */
export interface CancelSubscriptionCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof CancelSubscriptionCommand
     */
    subscriptionId: number;
    /**
     * Cancel date.
     * @type {string}
     * @memberof CancelSubscriptionCommand
     */
    cancellationDate: string;
    /**
     * Reason of the cancellation.
     * @type {CancelReasonId}
     * @memberof CancelSubscriptionCommand
     */
    cancelReasonId: CancelReasonId;
    /**
     * Id of uploaded file. Optional.
     * @type {string}
     * @memberof CancelSubscriptionCommand
     */
    uploadGuid?: string | null;
    /**
     * Explanation of the cancellation, optional.
     * @type {string}
     * @memberof CancelSubscriptionCommand
     */
    explanation?: string | null;
}
/**
 * Cancel a subscription.
 * @export
 * @interface CancelSubscriptionCommandAllOf
 */
export interface CancelSubscriptionCommandAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof CancelSubscriptionCommandAllOf
     */
    subscriptionId: number;
    /**
     * Cancel date.
     * @type {string}
     * @memberof CancelSubscriptionCommandAllOf
     */
    cancellationDate: string;
    /**
     * Reason of the cancellation.
     * @type {CancelReasonId}
     * @memberof CancelSubscriptionCommandAllOf
     */
    cancelReasonId: CancelReasonId;
    /**
     * Id of uploaded file. Optional.
     * @type {string}
     * @memberof CancelSubscriptionCommandAllOf
     */
    uploadGuid?: string | null;
    /**
     * Explanation of the cancellation, optional.
     * @type {string}
     * @memberof CancelSubscriptionCommandAllOf
     */
    explanation?: string | null;
}
/**
 * 
 * @export
 * @interface Command
 */
export interface Command {
}
/**
 * 
 * @export
 * @interface CommandOfNullableDateTime
 */
export interface CommandOfNullableDateTime {
}
/**
 * Address.
 * @export
 * @interface CompleteAddress
 */
export interface CompleteAddress {
    /**
     * Street name.
     * @type {string}
     * @memberof CompleteAddress
     */
    street?: string | null;
    /**
     * House number.
     * @type {number}
     * @memberof CompleteAddress
     */
    houseNumber?: number;
    /**
     * Postal code.
     * @type {string}
     * @memberof CompleteAddress
     */
    postalCode?: string | null;
    /**
     * City.
     * @type {string}
     * @memberof CompleteAddress
     */
    city?: string | null;
}
/**
 * Content.
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * Content type.
     * @type {string}
     * @memberof Content
     */
    type?: string | null;
}
/**
 * Covid suspend date.
 * @export
 * @interface CovidSuspendDateDto
 */
export interface CovidSuspendDateDto {
    /**
     * The suspend date.
     * @type {string}
     * @memberof CovidSuspendDateDto
     */
    suspendDate?: string;
    /**
     * Is suspend date today (true), or a date in the past (false).
     * @type {boolean}
     * @memberof CovidSuspendDateDto
     */
    isToday?: boolean;
}
/**
 * 
 * @export
 * @interface CovidSuspendSubscriptionCommand
 */
export interface CovidSuspendSubscriptionCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof CovidSuspendSubscriptionCommand
     */
    subscriptionId: number;
}
/**
 * Suspend a subscription for Covid lockdown.
 * @export
 * @interface CovidSuspendSubscriptionCommandAllOf
 */
export interface CovidSuspendSubscriptionCommandAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof CovidSuspendSubscriptionCommandAllOf
     */
    subscriptionId: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CovidSuspensionStatus {
    NotSuspended = 'NotSuspended',
    Processed = 'Processed',
    Suspended = 'Suspended',
    Requested = 'Requested'
}

/**
 * 
 * @export
 * @interface CreateCashBackCommand
 */
export interface CreateCashBackCommand {
    /**
     * SubscriptionId.
     * @type {number}
     * @memberof CreateCashBackCommand
     */
    subscriptionId: number;
    /**
     * IBAN account holder. Required if IBAN is provided.
     * @type {string}
     * @memberof CreateCashBackCommand
     */
    ibanAccountHolder: string;
    /**
     * IBAN. Since this field can be masked, we validate it in the validator.
     * @type {string}
     * @memberof CreateCashBackCommand
     */
    iban: string;
}
/**
 * Creates a CashBack entry.
 * @export
 * @interface CreateCashBackCommandAllOf
 */
export interface CreateCashBackCommandAllOf {
    /**
     * SubscriptionId.
     * @type {number}
     * @memberof CreateCashBackCommandAllOf
     */
    subscriptionId: number;
    /**
     * IBAN account holder. Required if IBAN is provided.
     * @type {string}
     * @memberof CreateCashBackCommandAllOf
     */
    ibanAccountHolder: string;
    /**
     * IBAN. Since this field can be masked, we validate it in the validator.
     * @type {string}
     * @memberof CreateCashBackCommandAllOf
     */
    iban: string;
}
/**
 * 
 * @export
 * @interface DeleteEmployeeDetailsCommand
 */
export interface DeleteEmployeeDetailsCommand {
}
/**
 * 
 * @export
 * @interface DeleteQueuedSubscriptionCancellationCommand
 */
export interface DeleteQueuedSubscriptionCancellationCommand {
    /**
     * SubscriptionId.
     * @type {number}
     * @memberof DeleteQueuedSubscriptionCancellationCommand
     */
    subscriptionId: number;
}
/**
 * Delete queued cancellation.
 * @export
 * @interface DeleteQueuedSubscriptionCancellationCommandAllOf
 */
export interface DeleteQueuedSubscriptionCancellationCommandAllOf {
    /**
     * SubscriptionId.
     * @type {number}
     * @memberof DeleteQueuedSubscriptionCancellationCommandAllOf
     */
    subscriptionId: number;
}
/**
 * Disable Totp on the current account.
 * @export
 * @interface DisableTotpRequest
 */
export interface DisableTotpRequest {
    /**
     * Current password.
     * @type {string}
     * @memberof DisableTotpRequest
     */
    password: string;
    /**
     * Current Totp pin.
     * @type {string}
     * @memberof DisableTotpRequest
     */
    pin: string;
}
/**
 * 
 * @export
 * @interface DownloadDeclarationPdfQuery
 */
export interface DownloadDeclarationPdfQuery {
    /**
     * Subscription.
     * @type {number}
     * @memberof DownloadDeclarationPdfQuery
     */
    subscriptionId: number;
}
/**
 * Download a declaration pdf. File is as-is on the moment of declaration.
 * @export
 * @interface DownloadDeclarationPdfQueryAllOf
 */
export interface DownloadDeclarationPdfQueryAllOf {
    /**
     * Subscription.
     * @type {number}
     * @memberof DownloadDeclarationPdfQueryAllOf
     */
    subscriptionId: number;
}
/**
 * 
 * @export
 * @interface EditSubscriptionCommand
 */
export interface EditSubscriptionCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof EditSubscriptionCommand
     */
    subscriptionId: number;
    /**
     * Article code of new requested article.
     * @type {string}
     * @memberof EditSubscriptionCommand
     */
    articleCode: string;
    /**
     * Explanation.
     * @type {string}
     * @memberof EditSubscriptionCommand
     */
    explanation?: string | null;
}
/**
 * Edit a subscription.
 * @export
 * @interface EditSubscriptionCommandAllOf
 */
export interface EditSubscriptionCommandAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof EditSubscriptionCommandAllOf
     */
    subscriptionId: number;
    /**
     * Article code of new requested article.
     * @type {string}
     * @memberof EditSubscriptionCommandAllOf
     */
    articleCode: string;
    /**
     * Explanation.
     * @type {string}
     * @memberof EditSubscriptionCommandAllOf
     */
    explanation?: string | null;
}
/**
 * 
 * @export
 * @interface EditSubscriptionEmployeeCommand
 */
export interface EditSubscriptionEmployeeCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof EditSubscriptionEmployeeCommand
     */
    subscriptionId: number;
    /**
     * Article code of new requested article.
     * @type {string}
     * @memberof EditSubscriptionEmployeeCommand
     */
    articleCode: string;
    /**
     * Explanation.
     * @type {string}
     * @memberof EditSubscriptionEmployeeCommand
     */
    explanation?: string | null;
}
/**
 * Employee details.
 * @export
 * @interface EmployeeDetailsDto
 */
export interface EmployeeDetailsDto {
    /**
     * Initials.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    initials?: string | null;
    /**
     * First name.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    firstName?: string | null;
    /**
     * Middle name.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    middleName?: string | null;
    /**
     * Last name.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    lastName?: string | null;
    /**
     * Full name.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    fullName?: string | null;
    /**
     * Address, street and housenumber.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    address?: string | null;
    /**
     * Postal code (Dutch format).
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    postalCode?: string | null;
    /**
     * City.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    city?: string | null;
    /**
     * Birth date.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    birthDate?: string | null;
    /**
     * Gender.
     * @type {Gender}
     * @memberof EmployeeDetailsDto
     */
    gender?: Gender;
    /**
     * Email address.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    emailAddress?: string | null;
    /**
     * Phone number personal.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    phonePersonal?: string | null;
    /**
     * Phone number business.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    phoneBusiness?: string | null;
    /**
     * IBAN. Masked.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    iban?: string | null;
    /**
     * IBAN account holder.
     * @type {string}
     * @memberof EmployeeDetailsDto
     */
    ibanAccountHolder?: string | null;
}
/**
 * 
 * @export
 * @interface EnableTotpRequest
 */
export interface EnableTotpRequest {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof EnableTotpRequest
     */
    emailAddress: string;
    /**
     * Password of the account.
     * @type {string}
     * @memberof EnableTotpRequest
     */
    password: string;
    /**
     * Current Totp pin.
     * @type {string}
     * @memberof EnableTotpRequest
     */
    pin: string;
}
/**
 * Enable Totp on the current account.
 * @export
 * @interface EnableTotpRequest2
 */
export interface EnableTotpRequest2 {
    /**
     * Current password.
     * @type {string}
     * @memberof EnableTotpRequest2
     */
    password: string;
    /**
     * Current Totp pin.
     * @type {string}
     * @memberof EnableTotpRequest2
     */
    pin: string;
}
/**
 * Enable Totp for this account.
 * @export
 * @interface EnableTotpRequestAllOf
 */
export interface EnableTotpRequestAllOf {
    /**
     * Current Totp pin.
     * @type {string}
     * @memberof EnableTotpRequestAllOf
     */
    pin: string;
}
/**
 * Faq item.
 * @export
 * @interface FaqItemDto
 */
export interface FaqItemDto {
    /**
     * Name.
     * @type {string}
     * @memberof FaqItemDto
     */
    name?: string | null;
    /**
     * Question.
     * @type {string}
     * @memberof FaqItemDto
     */
    question?: string | null;
    /**
     * Answer.
     * @type {string}
     * @memberof FaqItemDto
     */
    answer?: string | null;
    /**
     * Tags of this item.
     * @type {Array<string>}
     * @memberof FaqItemDto
     */
    tags?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Gender {
    Male = 'Male',
    Female = 'Female',
    X = 'X',
    Unknown = 'Unknown'
}

/**
 * 
 * @export
 * @interface GenerateDeclarationPdfQuery
 */
export interface GenerateDeclarationPdfQuery {
    /**
     * Subscription.
     * @type {number}
     * @memberof GenerateDeclarationPdfQuery
     */
    subscriptionId: number;
}
/**
 * Generate a declaration pdf. Based on current BrancheView data and sign-state.
 * @export
 * @interface GenerateDeclarationPdfQueryAllOf
 */
export interface GenerateDeclarationPdfQueryAllOf {
    /**
     * Subscription.
     * @type {number}
     * @memberof GenerateDeclarationPdfQueryAllOf
     */
    subscriptionId: number;
}
/**
 * 
 * @export
 * @interface GetCompleteAddressForTheNetherlandsQuery
 */
export interface GetCompleteAddressForTheNetherlandsQuery {
    /**
     * A Dutch postal code. With or without space between parts.
     * @type {string}
     * @memberof GetCompleteAddressForTheNetherlandsQuery
     */
    postalCode: string;
    /**
     * House number, without addition.
     * @type {number}
     * @memberof GetCompleteAddressForTheNetherlandsQuery
     */
    houseNumber: number;
}
/**
 * Get a complete address based on postal code and housenumber.
 * @export
 * @interface GetCompleteAddressForTheNetherlandsQueryAllOf
 */
export interface GetCompleteAddressForTheNetherlandsQueryAllOf {
    /**
     * A Dutch postal code. With or without space between parts.
     * @type {string}
     * @memberof GetCompleteAddressForTheNetherlandsQueryAllOf
     */
    postalCode: string;
    /**
     * House number, without addition.
     * @type {number}
     * @memberof GetCompleteAddressForTheNetherlandsQueryAllOf
     */
    houseNumber: number;
}
/**
 * 
 * @export
 * @interface GetPossibleSubscriptionEndDatesQuery
 */
export interface GetPossibleSubscriptionEndDatesQuery {
    /**
     * Subscription id.
     * @type {number}
     * @memberof GetPossibleSubscriptionEndDatesQuery
     */
    subscriptionId: number;
}
/**
 * Get possible subscription end dates.
 * @export
 * @interface GetPossibleSubscriptionEndDatesQueryAllOf
 */
export interface GetPossibleSubscriptionEndDatesQueryAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof GetPossibleSubscriptionEndDatesQueryAllOf
     */
    subscriptionId: number;
}
/**
 * 
 * @export
 * @interface GetQueuedCancellationQuery
 */
export interface GetQueuedCancellationQuery {
    /**
     * Subscription id.
     * @type {number}
     * @memberof GetQueuedCancellationQuery
     */
    subscriptionId: number;
}
/**
 * 
 * @export
 * @interface GetQueuedCancellationQueryAllOf
 */
export interface GetQueuedCancellationQueryAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof GetQueuedCancellationQueryAllOf
     */
    subscriptionId: number;
}
/**
 * 
 * @export
 * @interface GetTotpSetupCodeRequest
 */
export interface GetTotpSetupCodeRequest {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof GetTotpSetupCodeRequest
     */
    emailAddress: string;
    /**
     * Password of the account.
     * @type {string}
     * @memberof GetTotpSetupCodeRequest
     */
    password: string;
}
/**
 * Model for inspiration message
 * @export
 * @interface InspirationMessageDto
 */
export interface InspirationMessageDto {
    /**
     * Title.
     * @type {string}
     * @memberof InspirationMessageDto
     */
    title?: string | null;
    /**
     * Category of the message.
     * @type {string}
     * @memberof InspirationMessageDto
     */
    category?: string | null;
    /**
     * Publish date.
     * @type {string}
     * @memberof InspirationMessageDto
     */
    publishDate?: string;
    /**
     * Thumbnail url.
     * @type {string}
     * @memberof InspirationMessageDto
     */
    thumbnailUrl?: string | null;
    /**
     * Content.
     * @type {Array<Content>}
     * @memberof InspirationMessageDto
     */
    content?: Array<Content> | null;
}
/**
 * Check if the provided activation token in valid.
 * @export
 * @interface IsActivationTokenValidRequest
 */
export interface IsActivationTokenValidRequest {
    /**
     * Activation token.
     * @type {string}
     * @memberof IsActivationTokenValidRequest
     */
    activationToken: string;
}
/**
 * Check if password reset token is valid.
 * @export
 * @interface IsPasswordResetTokenValidRequest
 */
export interface IsPasswordResetTokenValidRequest {
    /**
     * The password reset token.
     * @type {string}
     * @memberof IsPasswordResetTokenValidRequest
     */
    passwordResetToken: string;
}
/**
 * Login to an account with credentials.
 * @export
 * @interface LoginWithCredentialsRequest
 */
export interface LoginWithCredentialsRequest {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof LoginWithCredentialsRequest
     */
    emailAddress: string;
    /**
     * Password of the account.
     * @type {string}
     * @memberof LoginWithCredentialsRequest
     */
    password: string;
}
/**
 * Login to an account with a login token.
 * @export
 * @interface LoginWithTokenRequest
 */
export interface LoginWithTokenRequest {
    /**
     * Login token.
     * @type {string}
     * @memberof LoginWithTokenRequest
     */
    loginToken: string;
}
/**
 * 
 * @export
 * @interface LoginWithTotpRequest
 */
export interface LoginWithTotpRequest {
    /**
     * Email address of the account.
     * @type {string}
     * @memberof LoginWithTotpRequest
     */
    emailAddress: string;
    /**
     * Password of the account.
     * @type {string}
     * @memberof LoginWithTotpRequest
     */
    password: string;
    /**
     * The current Totp pin of the account.
     * @type {string}
     * @memberof LoginWithTotpRequest
     */
    pin: string;
}
/**
 * Login to an account with credentials and Totp.
 * @export
 * @interface LoginWithTotpRequestAllOf
 */
export interface LoginWithTotpRequestAllOf {
    /**
     * The current Totp pin of the account.
     * @type {string}
     * @memberof LoginWithTotpRequestAllOf
     */
    pin: string;
}
/**
 * Logout. Note: there are no display attributes, since these fields are never presented to the end user.
 * @export
 * @interface LogoutRequest
 */
export interface LogoutRequest {
    /**
     * The token to refresh an expired access token with. This will be invalidated during logout.
     * @type {string}
     * @memberof LogoutRequest
     */
    refreshToken: string;
}
/**
 * Membership details.
 * @export
 * @interface MembershipDetailsDto
 */
export interface MembershipDetailsDto {
    /**
     * Employee.
     * @type {EmployeeDetailsDto}
     * @memberof MembershipDetailsDto
     */
    employeeDetails?: EmployeeDetailsDto | null;
    /**
     * Project.
     * @type {ProjectInformationDto}
     * @memberof MembershipDetailsDto
     */
    projectInformation?: ProjectInformationDto | null;
    /**
     * Subscription (or registration).
     * @type {SubscriptionDetailsDto}
     * @memberof MembershipDetailsDto
     */
    subscriptionDetails?: SubscriptionDetailsDto | null;
}
/**
 * Memo.
 * @export
 * @interface MemoDto
 */
export interface MemoDto {
    /**
     * Title.
     * @type {string}
     * @memberof MemoDto
     */
    title?: string | null;
    /**
     * Content.
     * @type {string}
     * @memberof MemoDto
     */
    content?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PersonType {
    SportLocation = 'SportLocation',
    Employer = 'Employer',
    Employee = 'Employee'
}

/**
 * A machine-readable format for specifying errors in HTTP API responses based on https://tools.ietf.org/html/rfc7807.
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * A URI reference [RFC3986] that identifies the problem type. This specification encourages that, when dereferenced, it provide human-readable documentation for the problem type (e.g., using HTML [W3C.REC-html5-20141028]). When this member is not present, its value is assumed to be \"about:blank\".
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * A short, human-readable summary of the problem type. It SHOULD NOT change from occurrence to occurrence of the problem, except for purposes of localization(e.g., using proactive content negotiation; see[RFC7231], Section 3.4).
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * The HTTP status code([RFC7231], Section 6) generated by the origin server for this occurrence of the problem.
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * A URI reference that identifies the specific occurrence of the problem. It may or may not yield further information if dereferenced.
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
    /**
     * Gets the IDictionary`2 for extension members.  Problem type definitions MAY extend the problem details object with additional members. Extension members appear in the same namespace as other members of a problem type.
     * @type {{ [key: string]: any; }}
     * @memberof ProblemDetails
     */
    extensions?: { [key: string]: any; };
}
/**
 * Account profile.
 * @export
 * @interface ProfileDto
 */
export interface ProfileDto {
    /**
     * Account id.
     * @type {number}
     * @memberof ProfileDto
     */
    accountId?: number;
    /**
     * Email address of account.
     * @type {string}
     * @memberof ProfileDto
     */
    emailAddress?: string | null;
    /**
     * Additional details of this account. Note: will be empty by default, must be filled by the implementation.
     * @type {{ [key: string]: any; }}
     * @memberof ProfileDto
     */
    properties?: { [key: string]: any; } | null;
}
/**
 * Project and arrangement.
 * @export
 * @interface ProjectInformationDto
 */
export interface ProjectInformationDto {
    /**
     * Full url logo.
     * @type {string}
     * @memberof ProjectInformationDto
     */
    logoUri?: string | null;
    /**
     * Name of project.
     * @type {string}
     * @memberof ProjectInformationDto
     */
    name?: string | null;
    /**
     * Textual representation of arrangement at time the subscription was requested.
     * @type {string}
     * @memberof ProjectInformationDto
     */
    arrangement?: string | null;
    /**
     * Download arrangement PDF file available?
     * @type {boolean}
     * @memberof ProjectInformationDto
     */
    arrangementPdfAvailable?: boolean;
    /**
     * Concern login code. Used to group subscriptions, and to construct login url.
     * @type {string}
     * @memberof ProjectInformationDto
     */
    concernLoginCode?: string | null;
    /**
     * Uri that points to the registration flow. In most cases this will be a link to BFNL, already logged in. Except for FiscFree registrations. In that case, it will link to the FiscFree website. Note, can also be null (for projects without a login).
     * @type {string}
     * @memberof ProjectInformationDto
     */
    registrationFlowLoginUri?: string | null;
    /**
     * Show Tell a Friend page or not in employee portal (based on project settings).
     * @type {boolean}
     * @memberof ProjectInformationDto
     */
    showTellAFriendPageInPortal?: boolean;
    /**
     * Show campaign page or not in employee portal (based on project settings).
     * @type {boolean}
     * @memberof ProjectInformationDto
     */
    showCampaignPageInPortal?: boolean;
    /**
     * Is expensive subscription check active? For employee and employer.
     * @type {boolean}
     * @memberof ProjectInformationDto
     */
    expensiveSubscriptionCheckActive?: boolean;
    /**
     * Price that is the absolute maximum used for expensive subscription check.
     * @type {number}
     * @memberof ProjectInformationDto
     */
    expensiveSubscriptionCheckThreshold?: number | null;
}
/**
 * 
 * @export
 * @interface QueryOfCompleteAddress
 */
export interface QueryOfCompleteAddress {
}
/**
 * 
 * @export
 * @interface QueryOfDocumentDto
 */
export interface QueryOfDocumentDto {
}
/**
 * 
 * @export
 * @interface QueryOfListOfDateTime
 */
export interface QueryOfListOfDateTime {
}
/**
 * 
 * @export
 * @interface QueryOfQueuedCancellationDto
 */
export interface QueryOfQueuedCancellationDto {
}
/**
 * Cancellation info.
 * @export
 * @interface QueuedCancellationDto
 */
export interface QueuedCancellationDto {
    /**
     * Requested cancellation date.
     * @type {string}
     * @memberof QueuedCancellationDto
     */
    cancellationDate?: string;
    /**
     * Date the cancellation will be processed by the system. Before this date, the cancellation can still be reverted by the user.
     * @type {string}
     * @memberof QueuedCancellationDto
     */
    processDate?: string;
    /**
     * Is this cancellation already processed?
     * @type {boolean}
     * @memberof QueuedCancellationDto
     */
    isProcessed?: boolean;
    /**
     * What is the reason of the cancellation?
     * @type {CancelReasonId}
     * @memberof QueuedCancellationDto
     */
    cancelReasonId?: CancelReasonId;
}
/**
 * Refresh the access token. Note: there are no display attributes, since these fields are never presented to the end user.
 * @export
 * @interface RefreshAccessTokenRequest
 */
export interface RefreshAccessTokenRequest {
    /**
     * The current JWT token to authenticate calls to the API.
     * @type {string}
     * @memberof RefreshAccessTokenRequest
     */
    accessToken: string;
    /**
     * The token to refresh an expired access token with.
     * @type {string}
     * @memberof RefreshAccessTokenRequest
     */
    refreshToken: string;
}
/**
 * A (pending) registration.
 * @export
 * @interface RegistrationOverviewDetailsDto
 */
export interface RegistrationOverviewDetailsDto {
    /**
     * Registration id.
     * @type {number}
     * @memberof RegistrationOverviewDetailsDto
     */
    registrationId?: number;
    /**
     * First name.
     * @type {string}
     * @memberof RegistrationOverviewDetailsDto
     */
    firstName?: string | null;
    /**
     * Middle name.
     * @type {string}
     * @memberof RegistrationOverviewDetailsDto
     */
    middleName?: string | null;
    /**
     * Last name.
     * @type {string}
     * @memberof RegistrationOverviewDetailsDto
     */
    lastName?: string | null;
    /**
     * Full name.
     * @type {string}
     * @memberof RegistrationOverviewDetailsDto
     */
    fullName?: string | null;
    /**
     * Date of registration.
     * @type {string}
     * @memberof RegistrationOverviewDetailsDto
     */
    registrationDate?: string;
    /**
     * Status of the registration (pending or cancelled).
     * @type {SubscriptionStatus}
     * @memberof RegistrationOverviewDetailsDto
     */
    subscriptionStatus?: SubscriptionStatus;
    /**
     * Declined date.
     * @type {string}
     * @memberof RegistrationOverviewDetailsDto
     */
    declinedDate?: string | null;
    /**
     * Declined reason.
     * @type {string}
     * @memberof RegistrationOverviewDetailsDto
     */
    declinedReason?: string | null;
}
/**
 * Request a password reset token.
 * @export
 * @interface RequestPasswordResetTokenRequest
 */
export interface RequestPasswordResetTokenRequest {
    /**
     * The email address of the account to reset the password of.
     * @type {string}
     * @memberof RequestPasswordResetTokenRequest
     */
    emailAddress: string;
}
/**
 * Reset a password.
 * @export
 * @interface ResetPasswordRequest
 */
export interface ResetPasswordRequest {
    /**
     * The password reset token.
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    passwordResetToken: string;
    /**
     * The new password.
     * @type {string}
     * @memberof ResetPasswordRequest
     */
    password: string;
}
/**
 * 
 * @export
 * @interface SendContactFormCommand
 */
export interface SendContactFormCommand {
    /**
     * Subscription id (when known, can be null for registrations).
     * @type {number}
     * @memberof SendContactFormCommand
     */
    subscriptionId?: number | null;
    /**
     * First name.
     * @type {string}
     * @memberof SendContactFormCommand
     */
    firstName: string;
    /**
     * Last name.
     * @type {string}
     * @memberof SendContactFormCommand
     */
    lastName: string;
    /**
     * Phone number.
     * @type {string}
     * @memberof SendContactFormCommand
     */
    phoneNumber: string;
    /**
     * Email address.
     * @type {string}
     * @memberof SendContactFormCommand
     */
    emailAddress: string;
    /**
     * Question.
     * @type {string}
     * @memberof SendContactFormCommand
     */
    question: string;
    /**
     * Type of person.
     * @type {PersonType}
     * @memberof SendContactFormCommand
     */
    personType: PersonType;
}
/**
 * Send contact form.
 * @export
 * @interface SendContactFormCommandAllOf
 */
export interface SendContactFormCommandAllOf {
    /**
     * Subscription id (when known, can be null for registrations).
     * @type {number}
     * @memberof SendContactFormCommandAllOf
     */
    subscriptionId?: number | null;
    /**
     * First name.
     * @type {string}
     * @memberof SendContactFormCommandAllOf
     */
    firstName: string;
    /**
     * Last name.
     * @type {string}
     * @memberof SendContactFormCommandAllOf
     */
    lastName: string;
    /**
     * Phone number.
     * @type {string}
     * @memberof SendContactFormCommandAllOf
     */
    phoneNumber: string;
    /**
     * Email address.
     * @type {string}
     * @memberof SendContactFormCommandAllOf
     */
    emailAddress: string;
    /**
     * Question.
     * @type {string}
     * @memberof SendContactFormCommandAllOf
     */
    question: string;
    /**
     * Type of person.
     * @type {PersonType}
     * @memberof SendContactFormCommandAllOf
     */
    personType: PersonType;
}
/**
 * 
 * @export
 * @interface ShareCodeByEmailToSelfCommand
 */
export interface ShareCodeByEmailToSelfCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof ShareCodeByEmailToSelfCommand
     */
    subscriptionId: number;
}
/**
 * Share your Tell a Friend code by sending an email to self.
 * @export
 * @interface ShareCodeByEmailToSelfCommandAllOf
 */
export interface ShareCodeByEmailToSelfCommandAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof ShareCodeByEmailToSelfCommandAllOf
     */
    subscriptionId: number;
}
/**
 * 
 * @export
 * @interface SignSubscriptionCommand
 */
export interface SignSubscriptionCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof SignSubscriptionCommand
     */
    subscriptionId: number;
    /**
     * AcceptTermsConditions (Algemene voorwaarden).
     * @type {boolean}
     * @memberof SignSubscriptionCommand
     */
    acceptTermsConditions?: boolean | null;
}
/**
 * SignSubscriptionCommand.
 * @export
 * @interface SignSubscriptionCommandAllOf
 */
export interface SignSubscriptionCommandAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof SignSubscriptionCommandAllOf
     */
    subscriptionId: number;
    /**
     * AcceptTermsConditions (Algemene voorwaarden).
     * @type {boolean}
     * @memberof SignSubscriptionCommandAllOf
     */
    acceptTermsConditions?: boolean | null;
}
/**
 * Subscription details.
 * @export
 * @interface SubscriptionDetailsDto
 */
export interface SubscriptionDetailsDto {
    /**
     * Full url logo.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    logoUri?: string | null;
    /**
     * Sport location FcNumber. Not for display purposes.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    sportLocationNumber?: string | null;
    /**
     * Name of sport location.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    sportLocationName?: string | null;
    /**
     * Address of sport location.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    address?: string | null;
    /**
     * Postal code (Dutch format) of sport location.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    postalCode?: string | null;
    /**
     * City of sport location.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    city?: string | null;
    /**
     * Duration in months.
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    durationInMonths?: number;
    /**
     * Start date.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    startDate?: string | null;
    /**
     * End date.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    endDate?: string | null;
    /**
     * Minimum subscription enddate added for High Five users.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    minimumEndDate?: string | null;
    /**
     * Suspended from.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    suspendedFrom?: string | null;
    /**
     * Suspended to.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    suspendedTo?: string | null;
    /**
     * Suspended comments.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    suspendedComments?: string | null;
    /**
     * Is automatic process of employee details update active?
     * @type {boolean}
     * @memberof SubscriptionDetailsDto
     */
    enableAutomaticUpdateEmployeeDetails?: boolean;
    /**
     * Indicates if Covid suspension is enabled (only show when true).
     * @type {boolean}
     * @memberof SubscriptionDetailsDto
     */
    enableCovidSuspensions?: boolean;
    /**
     * Status of Covid suspension.
     * @type {CovidSuspensionStatus}
     * @memberof SubscriptionDetailsDto
     */
    covidSuspensionStatus?: CovidSuspensionStatus;
    /**
     * Date the Covid suspension is registered.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    covidSuspensionDate?: string | null;
    /**
     * Indicates if there is a pending cancellation request. This indicates the requested cancellation data.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    cancellationDate?: string | null;
    /**
     * Indicates if there is a pending cancellation request. This indicated the process date of the cancellation.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    cancellationProcessDate?: string | null;
    /**
     * Status of the subscription.
     * @type {SubscriptionStatus}
     * @memberof SubscriptionDetailsDto
     */
    subscriptionStatus?: SubscriptionStatus;
    /**
     * Subscription number.
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    subscriptionId?: number;
    /**
     * Renewal number. 0 for initial subscription, 1 or higher for every renewal.
     * @type {number}
     * @memberof SubscriptionDetailsDto
     */
    renewalNumber?: number;
    /**
     * Referrer (BFNL or FiscFree).
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    referrer?: string | null;
    /**
     * Indicates that the subscription needs to be signed. Always false for registration, since the sport location needs to initialize this action, which can only be done on a subscription.
     * @type {boolean}
     * @memberof SubscriptionDetailsDto
     */
    subscriptionNeedsSigning?: boolean;
    /**
     * Indicates if the subscription document is available for download. Always false for registration, since the sport location needs to initialize this action, which can only be done on a subscription.
     * @type {boolean}
     * @memberof SubscriptionDetailsDto
     */
    subscriptionDocumentAvailable?: boolean;
    /**
     * Indicates if the subscription document needs to be generated (true), or downloaded from the archive (false). Only applicable when SubscriptionDocumentAvailable is true.
     * @type {boolean}
     * @memberof SubscriptionDetailsDto
     */
    subscriptionDocumentGenerate?: boolean | null;
    /**
     * Show Cashback page or not in employee portal (based on project settings and global system settings).
     * @type {boolean}
     * @memberof SubscriptionDetailsDto
     */
    showCashbackPageInPortal?: boolean;
    /**
     * Cashback end date. Only applicable when ShowCashbackPageInPortal is true.
     * @type {string}
     * @memberof SubscriptionDetailsDto
     */
    cashbackEndDate?: string | null;
    /**
     * Show a warning about the subscription being (too) expensive. Currently only for Deloitte project.
     * @type {boolean}
     * @memberof SubscriptionDetailsDto
     */
    showExpensiveSubscriptionWarning?: boolean;
    /**
     * Articles of subscription.
     * @type {Array<ArticleDto>}
     * @memberof SubscriptionDetailsDto
     */
    articles?: Array<ArticleDto> | null;
}
/**
 * A subscription.
 * @export
 * @interface SubscriptionOverviewDetailsDto
 */
export interface SubscriptionOverviewDetailsDto {
    /**
     * Subscription id.
     * @type {number}
     * @memberof SubscriptionOverviewDetailsDto
     */
    subscriptionId?: number;
    /**
     * First name.
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    firstName?: string | null;
    /**
     * Middle name.
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    middleName?: string | null;
    /**
     * Last name.
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    lastName?: string | null;
    /**
     * Full name.
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    fullName?: string | null;
    /**
     * Start date.
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    startDate?: string | null;
    /**
     * End date.
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    endDate?: string | null;
    /**
     * Status of the subscription.
     * @type {SubscriptionStatus}
     * @memberof SubscriptionOverviewDetailsDto
     */
    subscriptionStatus?: SubscriptionStatus;
    /**
     * Employee number
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    bfnlEmpNr?: string | null;
    /**
     * Email address of employee
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    emailBusiness?: string | null;
    /**
     * Sportlocation of subscription
     * @type {string}
     * @memberof SubscriptionOverviewDetailsDto
     */
    fcNumber?: string | null;
}
/**
 * Subscription and registration overview.
 * @export
 * @interface SubscriptionOverviewDto
 */
export interface SubscriptionOverviewDto {
    /**
     * All subscriptions.
     * @type {Array<SubscriptionOverviewDetailsDto>}
     * @memberof SubscriptionOverviewDto
     */
    subscriptions?: Array<SubscriptionOverviewDetailsDto> | null;
    /**
     * All (pending) registrations.
     * @type {Array<RegistrationOverviewDetailsDto>}
     * @memberof SubscriptionOverviewDto
     */
    registrations?: Array<RegistrationOverviewDetailsDto> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionStatus {
    Pending = 'Pending',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Active = 'Active',
    Ending = 'Ending',
    Suspended = 'Suspended',
    Ended = 'Ended',
    Cancelled = 'Cancelled',
    NeedsSigning = 'NeedsSigning',
    Expires = 'Expires'
}

/**
 * 
 * @export
 * @interface SuspendSubscriptionCommand
 */
export interface SuspendSubscriptionCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof SuspendSubscriptionCommand
     */
    subscriptionId: number;
    /**
     * Suspend start date.
     * @type {string}
     * @memberof SuspendSubscriptionCommand
     */
    suspendFrom: string;
    /**
     * Suspend end date.
     * @type {string}
     * @memberof SuspendSubscriptionCommand
     */
    suspendTo: string;
    /**
     * Id of uploaded file. Optional.
     * @type {string}
     * @memberof SuspendSubscriptionCommand
     */
    uploadId?: string | null;
    /**
     * Explanation.
     * @type {string}
     * @memberof SuspendSubscriptionCommand
     */
    explanation: string;
}
/**
 * Suspend a subscription.
 * @export
 * @interface SuspendSubscriptionCommandAllOf
 */
export interface SuspendSubscriptionCommandAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof SuspendSubscriptionCommandAllOf
     */
    subscriptionId: number;
    /**
     * Suspend start date.
     * @type {string}
     * @memberof SuspendSubscriptionCommandAllOf
     */
    suspendFrom: string;
    /**
     * Suspend end date.
     * @type {string}
     * @memberof SuspendSubscriptionCommandAllOf
     */
    suspendTo: string;
    /**
     * Id of uploaded file. Optional.
     * @type {string}
     * @memberof SuspendSubscriptionCommandAllOf
     */
    uploadId?: string | null;
    /**
     * Explanation.
     * @type {string}
     * @memberof SuspendSubscriptionCommandAllOf
     */
    explanation: string;
}
/**
 * Tell a Friend code.
 * @export
 * @interface TellAFriendCodeDto
 */
export interface TellAFriendCodeDto {
    /**
     * Tell a Friend code (formatted).
     * @type {string}
     * @memberof TellAFriendCodeDto
     */
    tellAFriendCode?: string | null;
    /**
     * Concern code.
     * @type {string}
     * @memberof TellAFriendCodeDto
     */
    concernLoginCode?: string | null;
    /**
     * Uri that points to the registration flow.
     * @type {string}
     * @memberof TellAFriendCodeDto
     */
    registrationFlowLoginUri?: string | null;
    /**
     * Selected gift.
     * @type {TellAFriendGift}
     * @memberof TellAFriendCodeDto
     */
    gift?: TellAFriendGift | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum TellAFriendGift {
    Towel = 'Towel',
    GymBag = 'GymBag',
    WaterBottle = 'WaterBottle'
}

/**
 * Totp setup code.
 * @export
 * @interface TotpSetupCodeImageDto
 */
export interface TotpSetupCodeImageDto {
    /**
     * The QR code, which can be rendered as an image.
     * @type {string}
     * @memberof TotpSetupCodeImageDto
     */
    qrSetupCode?: string | null;
    /**
     * For manual entry, if the QR code can not be scanned.
     * @type {string}
     * @memberof TotpSetupCodeImageDto
     */
    manualEntryKey?: string | null;
    /**
     * The QR code, already rendered as a base64 encoded PNG image.
     * @type {string}
     * @memberof TotpSetupCodeImageDto
     */
    qrSetupCodeImage?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateEmployeeDetailsCommand
 */
export interface UpdateEmployeeDetailsCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof UpdateEmployeeDetailsCommand
     */
    subscriptionId: number;
    /**
     * Address (street and housenumber).
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommand
     */
    address: string;
    /**
     * Postal code (Dutch format).
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommand
     */
    postalCode: string;
    /**
     * City.
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommand
     */
    city: string;
    /**
     * Phone number personal (instead of business number).
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommand
     */
    phonePersonal: string;
    /**
     * Phone number business (instead of personal number). Optional.
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommand
     */
    phoneBusiness?: string | null;
    /**
     * IBAN. Required if IBAN is already present. Since this field can be masked, we validate it in the validator.
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommand
     */
    iban?: string | null;
    /**
     * IBAN account holder. Required if IBAN is provided.
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommand
     */
    ibanAccountHolder?: string | null;
}
/**
 * Update employee details. Only fields that can be changed are asked.
 * @export
 * @interface UpdateEmployeeDetailsCommandAllOf
 */
export interface UpdateEmployeeDetailsCommandAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof UpdateEmployeeDetailsCommandAllOf
     */
    subscriptionId: number;
    /**
     * Address (street and housenumber).
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommandAllOf
     */
    address: string;
    /**
     * Postal code (Dutch format).
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommandAllOf
     */
    postalCode: string;
    /**
     * City.
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommandAllOf
     */
    city: string;
    /**
     * Phone number personal (instead of business number).
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommandAllOf
     */
    phonePersonal: string;
    /**
     * Phone number business (instead of personal number). Optional.
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommandAllOf
     */
    phoneBusiness?: string | null;
    /**
     * IBAN. Required if IBAN is already present. Since this field can be masked, we validate it in the validator.
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommandAllOf
     */
    iban?: string | null;
    /**
     * IBAN account holder. Required if IBAN is provided.
     * @type {string}
     * @memberof UpdateEmployeeDetailsCommandAllOf
     */
    ibanAccountHolder?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGiftCommand
 */
export interface UpdateGiftCommand {
    /**
     * Gift.
     * @type {TellAFriendGift}
     * @memberof UpdateGiftCommand
     */
    gift: TellAFriendGift;
}
/**
 * Update preferred gift.
 * @export
 * @interface UpdateGiftCommandAllOf
 */
export interface UpdateGiftCommandAllOf {
    /**
     * Gift.
     * @type {TellAFriendGift}
     * @memberof UpdateGiftCommandAllOf
     */
    gift: TellAFriendGift;
}
/**
 * Update the password of the current account.
 * @export
 * @interface UpdatePasswordRequest
 */
export interface UpdatePasswordRequest {
    /**
     * Current password.
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    password: string;
    /**
     * New password.
     * @type {string}
     * @memberof UpdatePasswordRequest
     */
    newPassword: string;
}
/**
 * 
 * @export
 * @interface UpdateSubscriptionSuspensionCommand
 */
export interface UpdateSubscriptionSuspensionCommand {
    /**
     * Subscription id.
     * @type {number}
     * @memberof UpdateSubscriptionSuspensionCommand
     */
    subscriptionId: number;
    /**
     * Suspend start date.
     * @type {string}
     * @memberof UpdateSubscriptionSuspensionCommand
     */
    suspendFrom: string;
    /**
     * Suspend end date.
     * @type {string}
     * @memberof UpdateSubscriptionSuspensionCommand
     */
    suspendTo: string;
    /**
     * Explanation.
     * @type {string}
     * @memberof UpdateSubscriptionSuspensionCommand
     */
    explanation?: string | null;
}
/**
 * Update the suspension of a subscription
 * @export
 * @interface UpdateSubscriptionSuspensionCommandAllOf
 */
export interface UpdateSubscriptionSuspensionCommandAllOf {
    /**
     * Subscription id.
     * @type {number}
     * @memberof UpdateSubscriptionSuspensionCommandAllOf
     */
    subscriptionId: number;
    /**
     * Suspend start date.
     * @type {string}
     * @memberof UpdateSubscriptionSuspensionCommandAllOf
     */
    suspendFrom: string;
    /**
     * Suspend end date.
     * @type {string}
     * @memberof UpdateSubscriptionSuspensionCommandAllOf
     */
    suspendTo: string;
    /**
     * Explanation.
     * @type {string}
     * @memberof UpdateSubscriptionSuspensionCommandAllOf
     */
    explanation?: string | null;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Disable 2FA using Totp for the current user.
         * @param {DisableTotpRequest} disableTotpRequest Request providing info needed to disable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDisableTotp: async (disableTotpRequest: DisableTotpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'disableTotpRequest' is not null or undefined
            assertParamExists('accountDisableTotp', 'disableTotpRequest', disableTotpRequest)
            const localVarPath = `/employeeportal/Account/DisableTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(disableTotpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest2} enableTotpRequest2 Request providing info needed to enable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEnableTotp: async (enableTotpRequest2: EnableTotpRequest2, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enableTotpRequest2' is not null or undefined
            assertParamExists('accountEnableTotp', 'enableTotpRequest2', enableTotpRequest2)
            const localVarPath = `/employeeportal/Account/EnableTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableTotpRequest2, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the profile of this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Account/GetProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the setup code needed to enable 2FA using Totp (Time-based One-time Password).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTotpSetupCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Account/GetTotpSetupCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if 2FA using Totp (Time-based One-time Password) is currently enabled for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIsTotpEnabled: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Account/IsTotpEnabled`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update the password of the currently logged in user.
         * @param {UpdatePasswordRequest} updatePasswordRequest Request providing the new password details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword: async (updatePasswordRequest: UpdatePasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordRequest' is not null or undefined
            assertParamExists('accountUpdatePassword', 'updatePasswordRequest', updatePasswordRequest)
            const localVarPath = `/employeeportal/Account/UpdatePassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Disable 2FA using Totp for the current user.
         * @param {DisableTotpRequest} disableTotpRequest Request providing info needed to disable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountDisableTotp(disableTotpRequest: DisableTotpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountDisableTotp(disableTotpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest2} enableTotpRequest2 Request providing info needed to enable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountEnableTotp(enableTotpRequest2: EnableTotpRequest2, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountEnableTotp(enableTotpRequest2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the profile of this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the setup code needed to enable 2FA using Totp (Time-based One-time Password).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountGetTotpSetupCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotpSetupCodeImageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountGetTotpSetupCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if 2FA using Totp (Time-based One-time Password) is currently enabled for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountIsTotpEnabled(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountIsTotpEnabled(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update the password of the currently logged in user.
         * @param {UpdatePasswordRequest} updatePasswordRequest Request providing the new password details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountUpdatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountUpdatePassword(updatePasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Disable 2FA using Totp for the current user.
         * @param {DisableTotpRequest} disableTotpRequest Request providing info needed to disable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountDisableTotp(disableTotpRequest: DisableTotpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.accountDisableTotp(disableTotpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest2} enableTotpRequest2 Request providing info needed to enable Totp.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountEnableTotp(enableTotpRequest2: EnableTotpRequest2, options?: any): AxiosPromise<void> {
            return localVarFp.accountEnableTotp(enableTotpRequest2, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the profile of this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetProfile(options?: any): AxiosPromise<ProfileDto> {
            return localVarFp.accountGetProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the setup code needed to enable 2FA using Totp (Time-based One-time Password).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountGetTotpSetupCode(options?: any): AxiosPromise<TotpSetupCodeImageDto> {
            return localVarFp.accountGetTotpSetupCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if 2FA using Totp (Time-based One-time Password) is currently enabled for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountIsTotpEnabled(options?: any): AxiosPromise<boolean> {
            return localVarFp.accountIsTotpEnabled(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update the password of the currently logged in user.
         * @param {UpdatePasswordRequest} updatePasswordRequest Request providing the new password details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountUpdatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.accountUpdatePassword(updatePasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Disable 2FA using Totp for the current user.
     * @param {DisableTotpRequest} disableTotpRequest Request providing info needed to disable Totp.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountDisableTotp(disableTotpRequest: DisableTotpRequest, options?: any) {
        return AccountApiFp(this.configuration).accountDisableTotp(disableTotpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
     * @param {EnableTotpRequest2} enableTotpRequest2 Request providing info needed to enable Totp.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountEnableTotp(enableTotpRequest2: EnableTotpRequest2, options?: any) {
        return AccountApiFp(this.configuration).accountEnableTotp(enableTotpRequest2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the profile of this account.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetProfile(options?: any) {
        return AccountApiFp(this.configuration).accountGetProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the setup code needed to enable 2FA using Totp (Time-based One-time Password).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountGetTotpSetupCode(options?: any) {
        return AccountApiFp(this.configuration).accountGetTotpSetupCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if 2FA using Totp (Time-based One-time Password) is currently enabled for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountIsTotpEnabled(options?: any) {
        return AccountApiFp(this.configuration).accountIsTotpEnabled(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update the password of the currently logged in user.
     * @param {UpdatePasswordRequest} updatePasswordRequest Request providing the new password details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public accountUpdatePassword(updatePasswordRequest: UpdatePasswordRequest, options?: any) {
        return AccountApiFp(this.configuration).accountUpdatePassword(updatePasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AddressCompletionApi - axios parameter creator
 * @export
 */
export const AddressCompletionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the complete address based on postalcode and housenumber.
         * @param {GetCompleteAddressForTheNetherlandsQuery} getCompleteAddressForTheNetherlandsQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressCompletionGetCompleteAddressForTheNetherlands: async (getCompleteAddressForTheNetherlandsQuery: GetCompleteAddressForTheNetherlandsQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCompleteAddressForTheNetherlandsQuery' is not null or undefined
            assertParamExists('addressCompletionGetCompleteAddressForTheNetherlands', 'getCompleteAddressForTheNetherlandsQuery', getCompleteAddressForTheNetherlandsQuery)
            const localVarPath = `/employeeportal/AddressCompletion/GetCompleteAddressForTheNetherlands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCompleteAddressForTheNetherlandsQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AddressCompletionApi - functional programming interface
 * @export
 */
export const AddressCompletionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AddressCompletionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get the complete address based on postalcode and housenumber.
         * @param {GetCompleteAddressForTheNetherlandsQuery} getCompleteAddressForTheNetherlandsQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addressCompletionGetCompleteAddressForTheNetherlands(getCompleteAddressForTheNetherlandsQuery: GetCompleteAddressForTheNetherlandsQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteAddress>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addressCompletionGetCompleteAddressForTheNetherlands(getCompleteAddressForTheNetherlandsQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AddressCompletionApi - factory interface
 * @export
 */
export const AddressCompletionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AddressCompletionApiFp(configuration)
    return {
        /**
         * 
         * @summary Get the complete address based on postalcode and housenumber.
         * @param {GetCompleteAddressForTheNetherlandsQuery} getCompleteAddressForTheNetherlandsQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addressCompletionGetCompleteAddressForTheNetherlands(getCompleteAddressForTheNetherlandsQuery: GetCompleteAddressForTheNetherlandsQuery, options?: any): AxiosPromise<CompleteAddress> {
            return localVarFp.addressCompletionGetCompleteAddressForTheNetherlands(getCompleteAddressForTheNetherlandsQuery, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AddressCompletionApi - object-oriented interface
 * @export
 * @class AddressCompletionApi
 * @extends {BaseAPI}
 */
export class AddressCompletionApi extends BaseAPI {
    /**
     * 
     * @summary Get the complete address based on postalcode and housenumber.
     * @param {GetCompleteAddressForTheNetherlandsQuery} getCompleteAddressForTheNetherlandsQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AddressCompletionApi
     */
    public addressCompletionGetCompleteAddressForTheNetherlands(getCompleteAddressForTheNetherlandsQuery: GetCompleteAddressForTheNetherlandsQuery, options?: any) {
        return AddressCompletionApiFp(this.configuration).addressCompletionGetCompleteAddressForTheNetherlands(getCompleteAddressForTheNetherlandsQuery, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Activate an account and set the password.
         * @param {ActivateAccountRequest} activateAccountRequest Request providing the activation details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationActivateAccount: async (activateAccountRequest: ActivateAccountRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'activateAccountRequest' is not null or undefined
            assertParamExists('authenticationActivateAccount', 'activateAccountRequest', activateAccountRequest)
            const localVarPath = `/employeeportal/Authentication/ActivateAccount`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activateAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest} enableTotpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationEnableTotp: async (enableTotpRequest: EnableTotpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'enableTotpRequest' is not null or undefined
            assertParamExists('authenticationEnableTotp', 'enableTotpRequest', enableTotpRequest)
            const localVarPath = `/employeeportal/Authentication/EnableTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enableTotpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a Totp (2FA) setup code when it is required, but not yet enabled for this account.
         * @param {GetTotpSetupCodeRequest} getTotpSetupCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationGetTotpSetupCode: async (getTotpSetupCodeRequest: GetTotpSetupCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTotpSetupCodeRequest' is not null or undefined
            assertParamExists('authenticationGetTotpSetupCode', 'getTotpSetupCodeRequest', getTotpSetupCodeRequest)
            const localVarPath = `/employeeportal/Authentication/GetTotpSetupCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTotpSetupCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if an account activation token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsActivationTokenValidRequest} isActivationTokenValidRequest Request providing the activation token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationIsActivationTokenValid: async (isActivationTokenValidRequest: IsActivationTokenValidRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'isActivationTokenValidRequest' is not null or undefined
            assertParamExists('authenticationIsActivationTokenValid', 'isActivationTokenValidRequest', isActivationTokenValidRequest)
            const localVarPath = `/employeeportal/Authentication/IsActivationTokenValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isActivationTokenValidRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if a password reset token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsPasswordResetTokenValidRequest} isPasswordResetTokenValidRequest Model providing the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationIsPasswordResetTokenValid: async (isPasswordResetTokenValidRequest: IsPasswordResetTokenValidRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'isPasswordResetTokenValidRequest' is not null or undefined
            assertParamExists('authenticationIsPasswordResetTokenValid', 'isPasswordResetTokenValidRequest', isPasswordResetTokenValidRequest)
            const localVarPath = `/employeeportal/Authentication/IsPasswordResetTokenValid`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(isPasswordResetTokenValidRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with credentials.
         * @param {LoginWithCredentialsRequest} loginWithCredentialsRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithCredentials: async (loginWithCredentialsRequest: LoginWithCredentialsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithCredentialsRequest' is not null or undefined
            assertParamExists('authenticationLoginWithCredentials', 'loginWithCredentialsRequest', loginWithCredentialsRequest)
            const localVarPath = `/employeeportal/Authentication/LoginWithCredentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithCredentialsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with a (one-time) login token. Bypasses Totp (2FA).
         * @param {LoginWithTokenRequest} loginWithTokenRequest Request providing the login token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithToken: async (loginWithTokenRequest: LoginWithTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithTokenRequest' is not null or undefined
            assertParamExists('authenticationLoginWithToken', 'loginWithTokenRequest', loginWithTokenRequest)
            const localVarPath = `/employeeportal/Authentication/LoginWithToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login with credentials and Totp.
         * @param {LoginWithTotpRequest} loginWithTotpRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithTotp: async (loginWithTotpRequest: LoginWithTotpRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginWithTotpRequest' is not null or undefined
            assertParamExists('authenticationLoginWithTotp', 'loginWithTotpRequest', loginWithTotpRequest)
            const localVarPath = `/employeeportal/Authentication/LoginWithTotp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginWithTotpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Logout. This invalidates the current JWT for future use. Also invalidates the provided refresh token.
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogout: async (logoutRequest: LogoutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'logoutRequest' is not null or undefined
            assertParamExists('authenticationLogout', 'logoutRequest', logoutRequest)
            const localVarPath = `/employeeportal/Authentication/Logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refresh an expired access token.
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest Model providing the token details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRefreshAccessToken: async (refreshAccessTokenRequest: RefreshAccessTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshAccessTokenRequest' is not null or undefined
            assertParamExists('authenticationRefreshAccessToken', 'refreshAccessTokenRequest', refreshAccessTokenRequest)
            const localVarPath = `/employeeportal/Authentication/RefreshAccessToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshAccessTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request a token (via email) to reset the password of the requested account. With this token a new password can be set. In case the account does not have a password, a new activation email will be send instead. This will always return 202 Accepted, even if the requested account does not exist.
         * @param {RequestPasswordResetTokenRequest} requestPasswordResetTokenRequest Model indicating the account for which a new password is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRequestPasswordResetToken: async (requestPasswordResetTokenRequest: RequestPasswordResetTokenRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPasswordResetTokenRequest' is not null or undefined
            assertParamExists('authenticationRequestPasswordResetToken', 'requestPasswordResetTokenRequest', requestPasswordResetTokenRequest)
            const localVarPath = `/employeeportal/Authentication/RequestPasswordResetToken`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPasswordResetTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset a password using the provided password reset token.
         * @param {ResetPasswordRequest} resetPasswordRequest Model providing the new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationResetPassword: async (resetPasswordRequest: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('authenticationResetPassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/employeeportal/Authentication/ResetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Activate an account and set the password.
         * @param {ActivateAccountRequest} activateAccountRequest Request providing the activation details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationActivateAccount(activateAccountRequest: ActivateAccountRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationActivateAccount(activateAccountRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest} enableTotpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationEnableTotp(enableTotpRequest: EnableTotpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationEnableTotp(enableTotpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a Totp (2FA) setup code when it is required, but not yet enabled for this account.
         * @param {GetTotpSetupCodeRequest} getTotpSetupCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationGetTotpSetupCode(getTotpSetupCodeRequest: GetTotpSetupCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotpSetupCodeImageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationGetTotpSetupCode(getTotpSetupCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if an account activation token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsActivationTokenValidRequest} isActivationTokenValidRequest Request providing the activation token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationIsActivationTokenValid(isActivationTokenValidRequest: IsActivationTokenValidRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationIsActivationTokenValid(isActivationTokenValidRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check if a password reset token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsPasswordResetTokenValidRequest} isPasswordResetTokenValidRequest Model providing the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest: IsPasswordResetTokenValidRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with credentials.
         * @param {LoginWithCredentialsRequest} loginWithCredentialsRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginWithCredentials(loginWithCredentialsRequest: LoginWithCredentialsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationLoginWithCredentials(loginWithCredentialsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with a (one-time) login token. Bypasses Totp (2FA).
         * @param {LoginWithTokenRequest} loginWithTokenRequest Request providing the login token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginWithToken(loginWithTokenRequest: LoginWithTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationLoginWithToken(loginWithTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login with credentials and Totp.
         * @param {LoginWithTotpRequest} loginWithTotpRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginWithTotp(loginWithTotpRequest: LoginWithTotpRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationLoginWithTotp(loginWithTotpRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Logout. This invalidates the current JWT for future use. Also invalidates the provided refresh token.
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLogout(logoutRequest: LogoutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationLogout(logoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refresh an expired access token.
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest Model providing the token details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationRefreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthorizedResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationRefreshAccessToken(refreshAccessTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request a token (via email) to reset the password of the requested account. With this token a new password can be set. In case the account does not have a password, a new activation email will be send instead. This will always return 202 Accepted, even if the requested account does not exist.
         * @param {RequestPasswordResetTokenRequest} requestPasswordResetTokenRequest Model indicating the account for which a new password is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest: RequestPasswordResetTokenRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reset a password using the provided password reset token.
         * @param {ResetPasswordRequest} resetPasswordRequest Model providing the new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationResetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationResetPassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Activate an account and set the password.
         * @param {ActivateAccountRequest} activateAccountRequest Request providing the activation details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationActivateAccount(activateAccountRequest: ActivateAccountRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationActivateAccount(activateAccountRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
         * @param {EnableTotpRequest} enableTotpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationEnableTotp(enableTotpRequest: EnableTotpRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationEnableTotp(enableTotpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a Totp (2FA) setup code when it is required, but not yet enabled for this account.
         * @param {GetTotpSetupCodeRequest} getTotpSetupCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationGetTotpSetupCode(getTotpSetupCodeRequest: GetTotpSetupCodeRequest, options?: any): AxiosPromise<TotpSetupCodeImageDto> {
            return localVarFp.authenticationGetTotpSetupCode(getTotpSetupCodeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if an account activation token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsActivationTokenValidRequest} isActivationTokenValidRequest Request providing the activation token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationIsActivationTokenValid(isActivationTokenValidRequest: IsActivationTokenValidRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationIsActivationTokenValid(isActivationTokenValidRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check if a password reset token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
         * @param {IsPasswordResetTokenValidRequest} isPasswordResetTokenValidRequest Model providing the token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest: IsPasswordResetTokenValidRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with credentials.
         * @param {LoginWithCredentialsRequest} loginWithCredentialsRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithCredentials(loginWithCredentialsRequest: LoginWithCredentialsRequest, options?: any): AxiosPromise<AuthorizedResultDto> {
            return localVarFp.authenticationLoginWithCredentials(loginWithCredentialsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with a (one-time) login token. Bypasses Totp (2FA).
         * @param {LoginWithTokenRequest} loginWithTokenRequest Request providing the login token.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithToken(loginWithTokenRequest: LoginWithTokenRequest, options?: any): AxiosPromise<AuthorizedResultDto> {
            return localVarFp.authenticationLoginWithToken(loginWithTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login with credentials and Totp.
         * @param {LoginWithTotpRequest} loginWithTotpRequest Request providing the login details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginWithTotp(loginWithTotpRequest: LoginWithTotpRequest, options?: any): AxiosPromise<AuthorizedResultDto> {
            return localVarFp.authenticationLoginWithTotp(loginWithTotpRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Logout. This invalidates the current JWT for future use. Also invalidates the provided refresh token.
         * @param {LogoutRequest} logoutRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogout(logoutRequest: LogoutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationLogout(logoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refresh an expired access token.
         * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest Model providing the token details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRefreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: any): AxiosPromise<AuthorizedResultDto> {
            return localVarFp.authenticationRefreshAccessToken(refreshAccessTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request a token (via email) to reset the password of the requested account. With this token a new password can be set. In case the account does not have a password, a new activation email will be send instead. This will always return 202 Accepted, even if the requested account does not exist.
         * @param {RequestPasswordResetTokenRequest} requestPasswordResetTokenRequest Model indicating the account for which a new password is requested.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest: RequestPasswordResetTokenRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reset a password using the provided password reset token.
         * @param {ResetPasswordRequest} resetPasswordRequest Model providing the new password.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationResetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.authenticationResetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Activate an account and set the password.
     * @param {ActivateAccountRequest} activateAccountRequest Request providing the activation details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationActivateAccount(activateAccountRequest: ActivateAccountRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationActivateAccount(activateAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Enable 2FA using Totp (Time-based One-time Password). A setup code should first be requested.
     * @param {EnableTotpRequest} enableTotpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationEnableTotp(enableTotpRequest: EnableTotpRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationEnableTotp(enableTotpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a Totp (2FA) setup code when it is required, but not yet enabled for this account.
     * @param {GetTotpSetupCodeRequest} getTotpSetupCodeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationGetTotpSetupCode(getTotpSetupCodeRequest: GetTotpSetupCodeRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationGetTotpSetupCode(getTotpSetupCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if an account activation token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
     * @param {IsActivationTokenValidRequest} isActivationTokenValidRequest Request providing the activation token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationIsActivationTokenValid(isActivationTokenValidRequest: IsActivationTokenValidRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationIsActivationTokenValid(isActivationTokenValidRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check if a password reset token is valid. This will return a 204 No Content, if the token is valid. This will return a 404 Not Found, if the token is not valid.
     * @param {IsPasswordResetTokenValidRequest} isPasswordResetTokenValidRequest Model providing the token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest: IsPasswordResetTokenValidRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationIsPasswordResetTokenValid(isPasswordResetTokenValidRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with credentials.
     * @param {LoginWithCredentialsRequest} loginWithCredentialsRequest Request providing the login details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLoginWithCredentials(loginWithCredentialsRequest: LoginWithCredentialsRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLoginWithCredentials(loginWithCredentialsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with a (one-time) login token. Bypasses Totp (2FA).
     * @param {LoginWithTokenRequest} loginWithTokenRequest Request providing the login token.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLoginWithToken(loginWithTokenRequest: LoginWithTokenRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLoginWithToken(loginWithTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login with credentials and Totp.
     * @param {LoginWithTotpRequest} loginWithTotpRequest Request providing the login details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLoginWithTotp(loginWithTotpRequest: LoginWithTotpRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLoginWithTotp(loginWithTotpRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Logout. This invalidates the current JWT for future use. Also invalidates the provided refresh token.
     * @param {LogoutRequest} logoutRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLogout(logoutRequest: LogoutRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationLogout(logoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refresh an expired access token.
     * @param {RefreshAccessTokenRequest} refreshAccessTokenRequest Model providing the token details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationRefreshAccessToken(refreshAccessTokenRequest: RefreshAccessTokenRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationRefreshAccessToken(refreshAccessTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request a token (via email) to reset the password of the requested account. With this token a new password can be set. In case the account does not have a password, a new activation email will be send instead. This will always return 202 Accepted, even if the requested account does not exist.
     * @param {RequestPasswordResetTokenRequest} requestPasswordResetTokenRequest Model indicating the account for which a new password is requested.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest: RequestPasswordResetTokenRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationRequestPasswordResetToken(requestPasswordResetTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reset a password using the provided password reset token.
     * @param {ResetPasswordRequest} resetPasswordRequest Model providing the new password.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationResetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any) {
        return AuthenticationApiFp(this.configuration).authenticationResetPassword(resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CampaignApi - axios parameter creator
 * @export
 */
export const CampaignApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a Campaign tip.
         * @param {AddCampaignTipAndGenerateLotteryNumbersCommand} addCampaignTipAndGenerateLotteryNumbersCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignCreateCampaignTip: async (addCampaignTipAndGenerateLotteryNumbersCommand: AddCampaignTipAndGenerateLotteryNumbersCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'addCampaignTipAndGenerateLotteryNumbersCommand' is not null or undefined
            assertParamExists('campaignCreateCampaignTip', 'addCampaignTipAndGenerateLotteryNumbersCommand', addCampaignTipAndGenerateLotteryNumbersCommand)
            const localVarPath = `/employeeportal/Campaign/CreateCampaignTip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addCampaignTipAndGenerateLotteryNumbersCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get e-mail preview.
         * @param {number} [registrationId] Registration id.
         * @param {string} [name] Name of employee.
         * @param {string} [colleagueName] Name of colleague
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignGetCampaignEmailPreview: async (registrationId?: number, name?: string, colleagueName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Campaign/GetCampaignEmailPreview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (registrationId !== undefined) {
                localVarQueryParameter['RegistrationId'] = registrationId;
            }

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (colleagueName !== undefined) {
                localVarQueryParameter['ColleagueName'] = colleagueName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignApi - functional programming interface
 * @export
 */
export const CampaignApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a Campaign tip.
         * @param {AddCampaignTipAndGenerateLotteryNumbersCommand} addCampaignTipAndGenerateLotteryNumbersCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignCreateCampaignTip(addCampaignTipAndGenerateLotteryNumbersCommand: AddCampaignTipAndGenerateLotteryNumbersCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignCreateCampaignTip(addCampaignTipAndGenerateLotteryNumbersCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get e-mail preview.
         * @param {number} [registrationId] Registration id.
         * @param {string} [name] Name of employee.
         * @param {string} [colleagueName] Name of colleague
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async campaignGetCampaignEmailPreview(registrationId?: number, name?: string, colleagueName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.campaignGetCampaignEmailPreview(registrationId, name, colleagueName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignApi - factory interface
 * @export
 */
export const CampaignApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a Campaign tip.
         * @param {AddCampaignTipAndGenerateLotteryNumbersCommand} addCampaignTipAndGenerateLotteryNumbersCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignCreateCampaignTip(addCampaignTipAndGenerateLotteryNumbersCommand: AddCampaignTipAndGenerateLotteryNumbersCommand, options?: any): AxiosPromise<void> {
            return localVarFp.campaignCreateCampaignTip(addCampaignTipAndGenerateLotteryNumbersCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get e-mail preview.
         * @param {number} [registrationId] Registration id.
         * @param {string} [name] Name of employee.
         * @param {string} [colleagueName] Name of colleague
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        campaignGetCampaignEmailPreview(registrationId?: number, name?: string, colleagueName?: string, options?: any): AxiosPromise<string> {
            return localVarFp.campaignGetCampaignEmailPreview(registrationId, name, colleagueName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignApi - object-oriented interface
 * @export
 * @class CampaignApi
 * @extends {BaseAPI}
 */
export class CampaignApi extends BaseAPI {
    /**
     * 
     * @summary Create a Campaign tip.
     * @param {AddCampaignTipAndGenerateLotteryNumbersCommand} addCampaignTipAndGenerateLotteryNumbersCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignCreateCampaignTip(addCampaignTipAndGenerateLotteryNumbersCommand: AddCampaignTipAndGenerateLotteryNumbersCommand, options?: any) {
        return CampaignApiFp(this.configuration).campaignCreateCampaignTip(addCampaignTipAndGenerateLotteryNumbersCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get e-mail preview.
     * @param {number} [registrationId] Registration id.
     * @param {string} [name] Name of employee.
     * @param {string} [colleagueName] Name of colleague
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignApi
     */
    public campaignGetCampaignEmailPreview(registrationId?: number, name?: string, colleagueName?: string, options?: any) {
        return CampaignApiFp(this.configuration).campaignGetCampaignEmailPreview(registrationId, name, colleagueName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CashBackApi - axios parameter creator
 * @export
 */
export const CashBackApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check if and CashBack is alread requested for this subscription.
         * @param {number} [subscriptionId] SubscriptionId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashBackCashBackRequested: async (subscriptionId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/CashBack/CashBackRequested`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['SubscriptionId'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a CashBack entry.
         * @param {CreateCashBackCommand} createCashBackCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashBackCreateCashBack: async (createCashBackCommand: CreateCashBackCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'createCashBackCommand' is not null or undefined
            assertParamExists('cashBackCreateCashBack', 'createCashBackCommand', createCashBackCommand)
            const localVarPath = `/employeeportal/CashBack/CreateCashBack`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCashBackCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CashBackApi - functional programming interface
 * @export
 */
export const CashBackApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CashBackApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check if and CashBack is alread requested for this subscription.
         * @param {number} [subscriptionId] SubscriptionId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashBackCashBackRequested(subscriptionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashBackCashBackRequested(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a CashBack entry.
         * @param {CreateCashBackCommand} createCashBackCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cashBackCreateCashBack(createCashBackCommand: CreateCashBackCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cashBackCreateCashBack(createCashBackCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CashBackApi - factory interface
 * @export
 */
export const CashBackApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CashBackApiFp(configuration)
    return {
        /**
         * 
         * @summary Check if and CashBack is alread requested for this subscription.
         * @param {number} [subscriptionId] SubscriptionId.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashBackCashBackRequested(subscriptionId?: number, options?: any): AxiosPromise<boolean> {
            return localVarFp.cashBackCashBackRequested(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a CashBack entry.
         * @param {CreateCashBackCommand} createCashBackCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cashBackCreateCashBack(createCashBackCommand: CreateCashBackCommand, options?: any): AxiosPromise<void> {
            return localVarFp.cashBackCreateCashBack(createCashBackCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CashBackApi - object-oriented interface
 * @export
 * @class CashBackApi
 * @extends {BaseAPI}
 */
export class CashBackApi extends BaseAPI {
    /**
     * 
     * @summary Check if and CashBack is alread requested for this subscription.
     * @param {number} [subscriptionId] SubscriptionId.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashBackApi
     */
    public cashBackCashBackRequested(subscriptionId?: number, options?: any) {
        return CashBackApiFp(this.configuration).cashBackCashBackRequested(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a CashBack entry.
     * @param {CreateCashBackCommand} createCashBackCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CashBackApi
     */
    public cashBackCreateCashBack(createCashBackCommand: CreateCashBackCommand, options?: any) {
        return CashBackApiFp(this.configuration).cashBackCreateCashBack(createCashBackCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send filled in contact form.
         * @param {SendContactFormCommand} sendContactFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSendContactForm: async (sendContactFormCommand: SendContactFormCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendContactFormCommand' is not null or undefined
            assertParamExists('contactSendContactForm', 'sendContactFormCommand', sendContactFormCommand)
            const localVarPath = `/employeeportal/Contact/SendContactForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendContactFormCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send filled in contact form.
         * @param {SendContactFormCommand} sendContactFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactSendContactForm(sendContactFormCommand: SendContactFormCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactSendContactForm(sendContactFormCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * 
         * @summary Send filled in contact form.
         * @param {SendContactFormCommand} sendContactFormCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSendContactForm(sendContactFormCommand: SendContactFormCommand, options?: any): AxiosPromise<void> {
            return localVarFp.contactSendContactForm(sendContactFormCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @summary Send filled in contact form.
     * @param {SendContactFormCommand} sendContactFormCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactSendContactForm(sendContactFormCommand: SendContactFormCommand, options?: any) {
        return ContactApiFp(this.configuration).contactSendContactForm(sendContactFormCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeclarationApi - axios parameter creator
 * @export
 */
export const DeclarationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Download a declaration pdf. File is as-is on the moment of declaration.
         * @param {DownloadDeclarationPdfQuery} downloadDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationDownloadDeclarationPdf: async (downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadDeclarationPdfQuery' is not null or undefined
            assertParamExists('declarationDownloadDeclarationPdf', 'downloadDeclarationPdfQuery', downloadDeclarationPdfQuery)
            const localVarPath = `/employeeportal/Declaration/DownloadDeclarationPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadDeclarationPdfQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a declaration pdf. Based on current BrancheView data and sign-state.
         * @param {GenerateDeclarationPdfQuery} generateDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationGenerateDeclarationPdf: async (generateDeclarationPdfQuery: GenerateDeclarationPdfQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'generateDeclarationPdfQuery' is not null or undefined
            assertParamExists('declarationGenerateDeclarationPdf', 'generateDeclarationPdfQuery', generateDeclarationPdfQuery)
            const localVarPath = `/employeeportal/Declaration/GenerateDeclarationPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateDeclarationPdfQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sign a subscription.
         * @param {SignSubscriptionCommand} signSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationSignSubscription: async (signSubscriptionCommand: SignSubscriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'signSubscriptionCommand' is not null or undefined
            assertParamExists('declarationSignSubscription', 'signSubscriptionCommand', signSubscriptionCommand)
            const localVarPath = `/employeeportal/Declaration/SignSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signSubscriptionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeclarationApi - functional programming interface
 * @export
 */
export const DeclarationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeclarationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Download a declaration pdf. File is as-is on the moment of declaration.
         * @param {DownloadDeclarationPdfQuery} downloadDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate a declaration pdf. Based on current BrancheView data and sign-state.
         * @param {GenerateDeclarationPdfQuery} generateDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declarationGenerateDeclarationPdf(generateDeclarationPdfQuery: GenerateDeclarationPdfQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declarationGenerateDeclarationPdf(generateDeclarationPdfQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sign a subscription.
         * @param {SignSubscriptionCommand} signSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async declarationSignSubscription(signSubscriptionCommand: SignSubscriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.declarationSignSubscription(signSubscriptionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeclarationApi - factory interface
 * @export
 */
export const DeclarationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeclarationApiFp(configuration)
    return {
        /**
         * 
         * @summary Download a declaration pdf. File is as-is on the moment of declaration.
         * @param {DownloadDeclarationPdfQuery} downloadDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery, options?: any): AxiosPromise<string> {
            return localVarFp.declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a declaration pdf. Based on current BrancheView data and sign-state.
         * @param {GenerateDeclarationPdfQuery} generateDeclarationPdfQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationGenerateDeclarationPdf(generateDeclarationPdfQuery: GenerateDeclarationPdfQuery, options?: any): AxiosPromise<string> {
            return localVarFp.declarationGenerateDeclarationPdf(generateDeclarationPdfQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sign a subscription.
         * @param {SignSubscriptionCommand} signSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        declarationSignSubscription(signSubscriptionCommand: SignSubscriptionCommand, options?: any): AxiosPromise<void> {
            return localVarFp.declarationSignSubscription(signSubscriptionCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeclarationApi - object-oriented interface
 * @export
 * @class DeclarationApi
 * @extends {BaseAPI}
 */
export class DeclarationApi extends BaseAPI {
    /**
     * 
     * @summary Download a declaration pdf. File is as-is on the moment of declaration.
     * @param {DownloadDeclarationPdfQuery} downloadDeclarationPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery: DownloadDeclarationPdfQuery, options?: any) {
        return DeclarationApiFp(this.configuration).declarationDownloadDeclarationPdf(downloadDeclarationPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a declaration pdf. Based on current BrancheView data and sign-state.
     * @param {GenerateDeclarationPdfQuery} generateDeclarationPdfQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public declarationGenerateDeclarationPdf(generateDeclarationPdfQuery: GenerateDeclarationPdfQuery, options?: any) {
        return DeclarationApiFp(this.configuration).declarationGenerateDeclarationPdf(generateDeclarationPdfQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sign a subscription.
     * @param {SignSubscriptionCommand} signSubscriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationApi
     */
    public declarationSignSubscription(signSubscriptionCommand: SignSubscriptionCommand, options?: any) {
        return DeclarationApiFp(this.configuration).declarationSignSubscription(signSubscriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FaqApi - axios parameter creator
 * @export
 */
export const FaqApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get faq items by tag.
         * @param {Array<string>} [tags] Tags a faq item must have at least one of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqGetFaqByTag: async (tags?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Faq/GetFaqByTag`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (tags) {
                localVarQueryParameter['Tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FaqApi - functional programming interface
 * @export
 */
export const FaqApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FaqApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get faq items by tag.
         * @param {Array<string>} [tags] Tags a faq item must have at least one of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async faqGetFaqByTag(tags?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FaqItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.faqGetFaqByTag(tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FaqApi - factory interface
 * @export
 */
export const FaqApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FaqApiFp(configuration)
    return {
        /**
         * 
         * @summary Get faq items by tag.
         * @param {Array<string>} [tags] Tags a faq item must have at least one of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        faqGetFaqByTag(tags?: Array<string>, options?: any): AxiosPromise<Array<FaqItemDto>> {
            return localVarFp.faqGetFaqByTag(tags, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FaqApi - object-oriented interface
 * @export
 * @class FaqApi
 * @extends {BaseAPI}
 */
export class FaqApi extends BaseAPI {
    /**
     * 
     * @summary Get faq items by tag.
     * @param {Array<string>} [tags] Tags a faq item must have at least one of.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FaqApi
     */
    public faqGetFaqByTag(tags?: Array<string>, options?: any) {
        return FaqApiFp(this.configuration).faqGetFaqByTag(tags, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * InspirationApi - axios parameter creator
 * @export
 */
export const InspirationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get inspiration messages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspirationGetInspirationMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Inspiration/GetInspirationMessages`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InspirationApi - functional programming interface
 * @export
 */
export const InspirationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InspirationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get inspiration messages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inspirationGetInspirationMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InspirationMessageDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inspirationGetInspirationMessages(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InspirationApi - factory interface
 * @export
 */
export const InspirationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InspirationApiFp(configuration)
    return {
        /**
         * 
         * @summary Get inspiration messages.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inspirationGetInspirationMessages(options?: any): AxiosPromise<Array<InspirationMessageDto>> {
            return localVarFp.inspirationGetInspirationMessages(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InspirationApi - object-oriented interface
 * @export
 * @class InspirationApi
 * @extends {BaseAPI}
 */
export class InspirationApi extends BaseAPI {
    /**
     * 
     * @summary Get inspiration messages.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InspirationApi
     */
    public inspirationGetInspirationMessages(options?: any) {
        return InspirationApiFp(this.configuration).inspirationGetInspirationMessages(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MaintenanceApi - axios parameter creator
 * @export
 */
export const MaintenanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get announcement message to dispay on portal login page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceGetAnnouncementText: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Maintenance/GetAnnouncementText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get maintenance message to dispay if portal is in maintenance. This will always return a message, even when maintenance is on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceGetMaintenanceText: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Maintenance/GetMaintenanceText`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Ping / callback function. Can be used to determine if the portal is available, or in maintenance (status 503).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePing: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Maintenance/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaintenanceApi - functional programming interface
 * @export
 */
export const MaintenanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaintenanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get announcement message to dispay on portal login page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceGetAnnouncementText(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceGetAnnouncementText(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get maintenance message to dispay if portal is in maintenance. This will always return a message, even when maintenance is on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenanceGetMaintenanceText(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MemoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenanceGetMaintenanceText(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Ping / callback function. Can be used to determine if the portal is available, or in maintenance (status 503).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async maintenancePing(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.maintenancePing(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaintenanceApi - factory interface
 * @export
 */
export const MaintenanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaintenanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get announcement message to dispay on portal login page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceGetAnnouncementText(options?: any): AxiosPromise<MemoDto> {
            return localVarFp.maintenanceGetAnnouncementText(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get maintenance message to dispay if portal is in maintenance. This will always return a message, even when maintenance is on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenanceGetMaintenanceText(options?: any): AxiosPromise<MemoDto> {
            return localVarFp.maintenanceGetMaintenanceText(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Ping / callback function. Can be used to determine if the portal is available, or in maintenance (status 503).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        maintenancePing(options?: any): AxiosPromise<void> {
            return localVarFp.maintenancePing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MaintenanceApi - object-oriented interface
 * @export
 * @class MaintenanceApi
 * @extends {BaseAPI}
 */
export class MaintenanceApi extends BaseAPI {
    /**
     * 
     * @summary Get announcement message to dispay on portal login page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public maintenanceGetAnnouncementText(options?: any) {
        return MaintenanceApiFp(this.configuration).maintenanceGetAnnouncementText(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get maintenance message to dispay if portal is in maintenance. This will always return a message, even when maintenance is on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public maintenanceGetMaintenanceText(options?: any) {
        return MaintenanceApiFp(this.configuration).maintenanceGetMaintenanceText(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Ping / callback function. Can be used to determine if the portal is available, or in maintenance (status 503).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaintenanceApi
     */
    public maintenancePing(options?: any) {
        return MaintenanceApiFp(this.configuration).maintenancePing(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Send a request to cancel a subscription.
         * @param {CancelSubscriptionCommand} cancelSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCancelSubscription: async (cancelSubscriptionCommand: CancelSubscriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cancelSubscriptionCommand' is not null or undefined
            assertParamExists('subscriptionCancelSubscription', 'cancelSubscriptionCommand', cancelSubscriptionCommand)
            const localVarPath = `/employeeportal/Subscription/CancelSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cancelSubscriptionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Suspend a subscription for Covid lockdown.
         * @param {CovidSuspendSubscriptionCommand} covidSuspendSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCovidSuspend: async (covidSuspendSubscriptionCommand: CovidSuspendSubscriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'covidSuspendSubscriptionCommand' is not null or undefined
            assertParamExists('subscriptionCovidSuspend', 'covidSuspendSubscriptionCommand', covidSuspendSubscriptionCommand)
            const localVarPath = `/employeeportal/Subscription/CovidSuspend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(covidSuspendSubscriptionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a request to delete the employee details. Note: the employee details will not be deleted directly.
         * @param {DeleteEmployeeDetailsCommand} deleteEmployeeDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDeleteDetails: async (deleteEmployeeDetailsCommand: DeleteEmployeeDetailsCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteEmployeeDetailsCommand' is not null or undefined
            assertParamExists('subscriptionDeleteDetails', 'deleteEmployeeDetailsCommand', deleteEmployeeDetailsCommand)
            const localVarPath = `/employeeportal/Subscription/DeleteDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteEmployeeDetailsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete queued cancellation.
         * @param {DeleteQueuedSubscriptionCancellationCommand} deleteQueuedSubscriptionCancellationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDeleteSubscriptionCancellation: async (deleteQueuedSubscriptionCancellationCommand: DeleteQueuedSubscriptionCancellationCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteQueuedSubscriptionCancellationCommand' is not null or undefined
            assertParamExists('subscriptionDeleteSubscriptionCancellation', 'deleteQueuedSubscriptionCancellationCommand', deleteQueuedSubscriptionCancellationCommand)
            const localVarPath = `/employeeportal/Subscription/DeleteSubscriptionCancellation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteQueuedSubscriptionCancellationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the AAO pdf.
         * @param {number} [registrationId] Article Registration id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDownloadAaoPdfByRegistration: async (registrationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/DownloadAaoPdfByRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (registrationId !== undefined) {
                localVarQueryParameter['RegistrationId'] = registrationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the AAO pdf.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDownloadAaoPdfBySubscription: async (subscriptionId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/DownloadAaoPdfBySubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['SubscriptionId'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a request to edit a subscription. Note: the subscription will not be edited directly.
         * @param {EditSubscriptionCommand} editSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionEditSubscription: async (editSubscriptionCommand: EditSubscriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editSubscriptionCommand' is not null or undefined
            assertParamExists('subscriptionEditSubscription', 'editSubscriptionCommand', editSubscriptionCommand)
            const localVarPath = `/employeeportal/Subscription/EditSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editSubscriptionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a confirmation mail of the edit request to the employee. Note: the subscription will not be edited directly.
         * @param {EditSubscriptionEmployeeCommand} editSubscriptionEmployeeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionEditSubscriptionEmployee: async (editSubscriptionEmployeeCommand: EditSubscriptionEmployeeCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'editSubscriptionEmployeeCommand' is not null or undefined
            assertParamExists('subscriptionEditSubscriptionEmployee', 'editSubscriptionEmployeeCommand', editSubscriptionEmployeeCommand)
            const localVarPath = `/employeeportal/Subscription/EditSubscriptionEmployee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editSubscriptionEmployeeCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cancel reasons.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetCancelReasons: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/GetCancelReasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Date a Covid suspension request is registered on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetCovidSuspendDate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/GetCovidSuspendDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get possible subscription end dates.
         * @param {GetPossibleSubscriptionEndDatesQuery} getPossibleSubscriptionEndDatesQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetPossibleSubscriptionEndDates: async (getPossibleSubscriptionEndDatesQuery: GetPossibleSubscriptionEndDatesQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPossibleSubscriptionEndDatesQuery' is not null or undefined
            assertParamExists('subscriptionGetPossibleSubscriptionEndDates', 'getPossibleSubscriptionEndDatesQuery', getPossibleSubscriptionEndDatesQuery)
            const localVarPath = `/employeeportal/Subscription/GetPossibleSubscriptionEndDates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPossibleSubscriptionEndDatesQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get possible subscription end dates (for testing, not to be called in the Portal).
         * @param {string} [endDate] 
         * @param {number} [duration] 
         * @param {boolean} [isRenewal] 
         * @param {boolean} [isWorkit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetPossibleSubscriptionEndDatesTest: async (endDate?: string, duration?: number, isRenewal?: boolean, isWorkit?: boolean, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/GetPossibleSubscriptionEndDatesTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (duration !== undefined) {
                localVarQueryParameter['duration'] = duration;
            }

            if (isRenewal !== undefined) {
                localVarQueryParameter['isRenewal'] = isRenewal;
            }

            if (isWorkit !== undefined) {
                localVarQueryParameter['isWorkit'] = isWorkit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info on queued cancellation. Returns empty object if cancellation in queue.
         * @param {GetQueuedCancellationQuery} getQueuedCancellationQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetQueuedCancellationInfo: async (getQueuedCancellationQuery: GetQueuedCancellationQuery, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'getQueuedCancellationQuery' is not null or undefined
            assertParamExists('subscriptionGetQueuedCancellationInfo', 'getQueuedCancellationQuery', getQueuedCancellationQuery)
            const localVarPath = `/employeeportal/Subscription/GetQueuedCancellationInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getQueuedCancellationQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of the pending subscription, employee, and employer.
         * @param {number} [registrationId] Registration id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetRegistration: async (registrationId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/GetRegistration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (registrationId !== undefined) {
                localVarQueryParameter['RegistrationId'] = registrationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available articles from this sport location.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSportLocationArticles: async (subscriptionId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/GetSportLocationArticles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['SubscriptionId'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of the subscription, employee, and employer.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscription: async (subscriptionId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/GetSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['SubscriptionId'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get subscription overview for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionOverview: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Subscription/GetSubscriptionOverview`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a request to suspend a subscription. Note: the subscription will not be suspended directly.
         * @param {SuspendSubscriptionCommand} suspendSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionSuspend: async (suspendSubscriptionCommand: SuspendSubscriptionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'suspendSubscriptionCommand' is not null or undefined
            assertParamExists('subscriptionSuspend', 'suspendSubscriptionCommand', suspendSubscriptionCommand)
            const localVarPath = `/employeeportal/Subscription/Suspend`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(suspendSubscriptionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a request to edit the employee details. Note: the employee details will not be updated directly.
         * @param {UpdateEmployeeDetailsCommand} updateEmployeeDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateDetails: async (updateEmployeeDetailsCommand: UpdateEmployeeDetailsCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateEmployeeDetailsCommand' is not null or undefined
            assertParamExists('subscriptionUpdateDetails', 'updateEmployeeDetailsCommand', updateEmployeeDetailsCommand)
            const localVarPath = `/employeeportal/Subscription/UpdateDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateEmployeeDetailsCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send a request to update the suspension of a subscription. Note: the changes will not be processed directly.
         * @param {UpdateSubscriptionSuspensionCommand} updateSubscriptionSuspensionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateSubscriptionSuspension: async (updateSubscriptionSuspensionCommand: UpdateSubscriptionSuspensionCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSubscriptionSuspensionCommand' is not null or undefined
            assertParamExists('subscriptionUpdateSubscriptionSuspension', 'updateSubscriptionSuspensionCommand', updateSubscriptionSuspensionCommand)
            const localVarPath = `/employeeportal/Subscription/UpdateSubscriptionSuspension`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSubscriptionSuspensionCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Send a request to cancel a subscription.
         * @param {CancelSubscriptionCommand} cancelSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionCancelSubscription(cancelSubscriptionCommand: CancelSubscriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionCancelSubscription(cancelSubscriptionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Suspend a subscription for Covid lockdown.
         * @param {CovidSuspendSubscriptionCommand} covidSuspendSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionCovidSuspend(covidSuspendSubscriptionCommand: CovidSuspendSubscriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionCovidSuspend(covidSuspendSubscriptionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a request to delete the employee details. Note: the employee details will not be deleted directly.
         * @param {DeleteEmployeeDetailsCommand} deleteEmployeeDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionDeleteDetails(deleteEmployeeDetailsCommand: DeleteEmployeeDetailsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionDeleteDetails(deleteEmployeeDetailsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete queued cancellation.
         * @param {DeleteQueuedSubscriptionCancellationCommand} deleteQueuedSubscriptionCancellationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionDeleteSubscriptionCancellation(deleteQueuedSubscriptionCancellationCommand: DeleteQueuedSubscriptionCancellationCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionDeleteSubscriptionCancellation(deleteQueuedSubscriptionCancellationCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the AAO pdf.
         * @param {number} [registrationId] Article Registration id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionDownloadAaoPdfByRegistration(registrationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionDownloadAaoPdfByRegistration(registrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get the AAO pdf.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionDownloadAaoPdfBySubscription(subscriptionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionDownloadAaoPdfBySubscription(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a request to edit a subscription. Note: the subscription will not be edited directly.
         * @param {EditSubscriptionCommand} editSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionEditSubscription(editSubscriptionCommand: EditSubscriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionEditSubscription(editSubscriptionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a confirmation mail of the edit request to the employee. Note: the subscription will not be edited directly.
         * @param {EditSubscriptionEmployeeCommand} editSubscriptionEmployeeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionEditSubscriptionEmployee(editSubscriptionEmployeeCommand: EditSubscriptionEmployeeCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionEditSubscriptionEmployee(editSubscriptionEmployeeCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get cancel reasons.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetCancelReasons(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CancelReasonDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetCancelReasons(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Date a Covid suspension request is registered on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetCovidSuspendDate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CovidSuspendDateDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetCovidSuspendDate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get possible subscription end dates.
         * @param {GetPossibleSubscriptionEndDatesQuery} getPossibleSubscriptionEndDatesQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetPossibleSubscriptionEndDates(getPossibleSubscriptionEndDatesQuery: GetPossibleSubscriptionEndDatesQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetPossibleSubscriptionEndDates(getPossibleSubscriptionEndDatesQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get possible subscription end dates (for testing, not to be called in the Portal).
         * @param {string} [endDate] 
         * @param {number} [duration] 
         * @param {boolean} [isRenewal] 
         * @param {boolean} [isWorkit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetPossibleSubscriptionEndDatesTest(endDate?: string, duration?: number, isRenewal?: boolean, isWorkit?: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetPossibleSubscriptionEndDatesTest(endDate, duration, isRenewal, isWorkit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get info on queued cancellation. Returns empty object if cancellation in queue.
         * @param {GetQueuedCancellationQuery} getQueuedCancellationQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetQueuedCancellationInfo(getQueuedCancellationQuery: GetQueuedCancellationQuery, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueuedCancellationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetQueuedCancellationInfo(getQueuedCancellationQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details of the pending subscription, employee, and employer.
         * @param {number} [registrationId] Registration id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetRegistration(registrationId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetRegistration(registrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available articles from this sport location.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSportLocationArticles(subscriptionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ArticleDto2>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetSportLocationArticles(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get details of the subscription, employee, and employer.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscription(subscriptionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MembershipDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetSubscription(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get subscription overview for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionGetSubscriptionOverview(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionOverviewDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionGetSubscriptionOverview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a request to suspend a subscription. Note: the subscription will not be suspended directly.
         * @param {SuspendSubscriptionCommand} suspendSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionSuspend(suspendSubscriptionCommand: SuspendSubscriptionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionSuspend(suspendSubscriptionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a request to edit the employee details. Note: the employee details will not be updated directly.
         * @param {UpdateEmployeeDetailsCommand} updateEmployeeDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionUpdateDetails(updateEmployeeDetailsCommand: UpdateEmployeeDetailsCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionUpdateDetails(updateEmployeeDetailsCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Send a request to update the suspension of a subscription. Note: the changes will not be processed directly.
         * @param {UpdateSubscriptionSuspensionCommand} updateSubscriptionSuspensionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscriptionUpdateSubscriptionSuspension(updateSubscriptionSuspensionCommand: UpdateSubscriptionSuspensionCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscriptionUpdateSubscriptionSuspension(updateSubscriptionSuspensionCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionApiFp(configuration)
    return {
        /**
         * 
         * @summary Send a request to cancel a subscription.
         * @param {CancelSubscriptionCommand} cancelSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCancelSubscription(cancelSubscriptionCommand: CancelSubscriptionCommand, options?: any): AxiosPromise<string> {
            return localVarFp.subscriptionCancelSubscription(cancelSubscriptionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Suspend a subscription for Covid lockdown.
         * @param {CovidSuspendSubscriptionCommand} covidSuspendSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionCovidSuspend(covidSuspendSubscriptionCommand: CovidSuspendSubscriptionCommand, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionCovidSuspend(covidSuspendSubscriptionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a request to delete the employee details. Note: the employee details will not be deleted directly.
         * @param {DeleteEmployeeDetailsCommand} deleteEmployeeDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDeleteDetails(deleteEmployeeDetailsCommand: DeleteEmployeeDetailsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionDeleteDetails(deleteEmployeeDetailsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete queued cancellation.
         * @param {DeleteQueuedSubscriptionCancellationCommand} deleteQueuedSubscriptionCancellationCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDeleteSubscriptionCancellation(deleteQueuedSubscriptionCancellationCommand: DeleteQueuedSubscriptionCancellationCommand, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionDeleteSubscriptionCancellation(deleteQueuedSubscriptionCancellationCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the AAO pdf.
         * @param {number} [registrationId] Article Registration id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDownloadAaoPdfByRegistration(registrationId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.subscriptionDownloadAaoPdfByRegistration(registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the AAO pdf.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionDownloadAaoPdfBySubscription(subscriptionId?: number, options?: any): AxiosPromise<string> {
            return localVarFp.subscriptionDownloadAaoPdfBySubscription(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a request to edit a subscription. Note: the subscription will not be edited directly.
         * @param {EditSubscriptionCommand} editSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionEditSubscription(editSubscriptionCommand: EditSubscriptionCommand, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionEditSubscription(editSubscriptionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a confirmation mail of the edit request to the employee. Note: the subscription will not be edited directly.
         * @param {EditSubscriptionEmployeeCommand} editSubscriptionEmployeeCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionEditSubscriptionEmployee(editSubscriptionEmployeeCommand: EditSubscriptionEmployeeCommand, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionEditSubscriptionEmployee(editSubscriptionEmployeeCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cancel reasons.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetCancelReasons(options?: any): AxiosPromise<Array<CancelReasonDto>> {
            return localVarFp.subscriptionGetCancelReasons(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Date a Covid suspension request is registered on.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetCovidSuspendDate(options?: any): AxiosPromise<CovidSuspendDateDto> {
            return localVarFp.subscriptionGetCovidSuspendDate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get possible subscription end dates.
         * @param {GetPossibleSubscriptionEndDatesQuery} getPossibleSubscriptionEndDatesQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetPossibleSubscriptionEndDates(getPossibleSubscriptionEndDatesQuery: GetPossibleSubscriptionEndDatesQuery, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.subscriptionGetPossibleSubscriptionEndDates(getPossibleSubscriptionEndDatesQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get possible subscription end dates (for testing, not to be called in the Portal).
         * @param {string} [endDate] 
         * @param {number} [duration] 
         * @param {boolean} [isRenewal] 
         * @param {boolean} [isWorkit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetPossibleSubscriptionEndDatesTest(endDate?: string, duration?: number, isRenewal?: boolean, isWorkit?: boolean, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.subscriptionGetPossibleSubscriptionEndDatesTest(endDate, duration, isRenewal, isWorkit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info on queued cancellation. Returns empty object if cancellation in queue.
         * @param {GetQueuedCancellationQuery} getQueuedCancellationQuery 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetQueuedCancellationInfo(getQueuedCancellationQuery: GetQueuedCancellationQuery, options?: any): AxiosPromise<QueuedCancellationDto> {
            return localVarFp.subscriptionGetQueuedCancellationInfo(getQueuedCancellationQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of the pending subscription, employee, and employer.
         * @param {number} [registrationId] Registration id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetRegistration(registrationId?: number, options?: any): AxiosPromise<MembershipDetailsDto> {
            return localVarFp.subscriptionGetRegistration(registrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available articles from this sport location.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSportLocationArticles(subscriptionId?: number, options?: any): AxiosPromise<Array<ArticleDto2>> {
            return localVarFp.subscriptionGetSportLocationArticles(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of the subscription, employee, and employer.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscription(subscriptionId?: number, options?: any): AxiosPromise<MembershipDetailsDto> {
            return localVarFp.subscriptionGetSubscription(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get subscription overview for current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionGetSubscriptionOverview(options?: any): AxiosPromise<SubscriptionOverviewDto> {
            return localVarFp.subscriptionGetSubscriptionOverview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a request to suspend a subscription. Note: the subscription will not be suspended directly.
         * @param {SuspendSubscriptionCommand} suspendSubscriptionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionSuspend(suspendSubscriptionCommand: SuspendSubscriptionCommand, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionSuspend(suspendSubscriptionCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a request to edit the employee details. Note: the employee details will not be updated directly.
         * @param {UpdateEmployeeDetailsCommand} updateEmployeeDetailsCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateDetails(updateEmployeeDetailsCommand: UpdateEmployeeDetailsCommand, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionUpdateDetails(updateEmployeeDetailsCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send a request to update the suspension of a subscription. Note: the changes will not be processed directly.
         * @param {UpdateSubscriptionSuspensionCommand} updateSubscriptionSuspensionCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscriptionUpdateSubscriptionSuspension(updateSubscriptionSuspensionCommand: UpdateSubscriptionSuspensionCommand, options?: any): AxiosPromise<void> {
            return localVarFp.subscriptionUpdateSubscriptionSuspension(updateSubscriptionSuspensionCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
    /**
     * 
     * @summary Send a request to cancel a subscription.
     * @param {CancelSubscriptionCommand} cancelSubscriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionCancelSubscription(cancelSubscriptionCommand: CancelSubscriptionCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionCancelSubscription(cancelSubscriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Suspend a subscription for Covid lockdown.
     * @param {CovidSuspendSubscriptionCommand} covidSuspendSubscriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionCovidSuspend(covidSuspendSubscriptionCommand: CovidSuspendSubscriptionCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionCovidSuspend(covidSuspendSubscriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a request to delete the employee details. Note: the employee details will not be deleted directly.
     * @param {DeleteEmployeeDetailsCommand} deleteEmployeeDetailsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionDeleteDetails(deleteEmployeeDetailsCommand: DeleteEmployeeDetailsCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionDeleteDetails(deleteEmployeeDetailsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete queued cancellation.
     * @param {DeleteQueuedSubscriptionCancellationCommand} deleteQueuedSubscriptionCancellationCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionDeleteSubscriptionCancellation(deleteQueuedSubscriptionCancellationCommand: DeleteQueuedSubscriptionCancellationCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionDeleteSubscriptionCancellation(deleteQueuedSubscriptionCancellationCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the AAO pdf.
     * @param {number} [registrationId] Article Registration id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionDownloadAaoPdfByRegistration(registrationId?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionDownloadAaoPdfByRegistration(registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the AAO pdf.
     * @param {number} [subscriptionId] Subscription id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionDownloadAaoPdfBySubscription(subscriptionId?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionDownloadAaoPdfBySubscription(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a request to edit a subscription. Note: the subscription will not be edited directly.
     * @param {EditSubscriptionCommand} editSubscriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionEditSubscription(editSubscriptionCommand: EditSubscriptionCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionEditSubscription(editSubscriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a confirmation mail of the edit request to the employee. Note: the subscription will not be edited directly.
     * @param {EditSubscriptionEmployeeCommand} editSubscriptionEmployeeCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionEditSubscriptionEmployee(editSubscriptionEmployeeCommand: EditSubscriptionEmployeeCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionEditSubscriptionEmployee(editSubscriptionEmployeeCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cancel reasons.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetCancelReasons(options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetCancelReasons(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Date a Covid suspension request is registered on.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetCovidSuspendDate(options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetCovidSuspendDate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get possible subscription end dates.
     * @param {GetPossibleSubscriptionEndDatesQuery} getPossibleSubscriptionEndDatesQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetPossibleSubscriptionEndDates(getPossibleSubscriptionEndDatesQuery: GetPossibleSubscriptionEndDatesQuery, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetPossibleSubscriptionEndDates(getPossibleSubscriptionEndDatesQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get possible subscription end dates (for testing, not to be called in the Portal).
     * @param {string} [endDate] 
     * @param {number} [duration] 
     * @param {boolean} [isRenewal] 
     * @param {boolean} [isWorkit] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetPossibleSubscriptionEndDatesTest(endDate?: string, duration?: number, isRenewal?: boolean, isWorkit?: boolean, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetPossibleSubscriptionEndDatesTest(endDate, duration, isRenewal, isWorkit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info on queued cancellation. Returns empty object if cancellation in queue.
     * @param {GetQueuedCancellationQuery} getQueuedCancellationQuery 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetQueuedCancellationInfo(getQueuedCancellationQuery: GetQueuedCancellationQuery, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetQueuedCancellationInfo(getQueuedCancellationQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details of the pending subscription, employee, and employer.
     * @param {number} [registrationId] Registration id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetRegistration(registrationId?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetRegistration(registrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available articles from this sport location.
     * @param {number} [subscriptionId] Subscription id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSportLocationArticles(subscriptionId?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSportLocationArticles(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details of the subscription, employee, and employer.
     * @param {number} [subscriptionId] Subscription id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscription(subscriptionId?: number, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscription(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get subscription overview for current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionGetSubscriptionOverview(options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionGetSubscriptionOverview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a request to suspend a subscription. Note: the subscription will not be suspended directly.
     * @param {SuspendSubscriptionCommand} suspendSubscriptionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionSuspend(suspendSubscriptionCommand: SuspendSubscriptionCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionSuspend(suspendSubscriptionCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a request to edit the employee details. Note: the employee details will not be updated directly.
     * @param {UpdateEmployeeDetailsCommand} updateEmployeeDetailsCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionUpdateDetails(updateEmployeeDetailsCommand: UpdateEmployeeDetailsCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionUpdateDetails(updateEmployeeDetailsCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send a request to update the suspension of a subscription. Note: the changes will not be processed directly.
     * @param {UpdateSubscriptionSuspensionCommand} updateSubscriptionSuspensionCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApi
     */
    public subscriptionUpdateSubscriptionSuspension(updateSubscriptionSuspensionCommand: UpdateSubscriptionSuspensionCommand, options?: any) {
        return SubscriptionApiFp(this.configuration).subscriptionUpdateSubscriptionSuspension(updateSubscriptionSuspensionCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TellAFriendApi - axios parameter creator
 * @export
 */
export const TellAFriendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Tell a Friend code.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tellAFriendGetTellAFriendCode: async (subscriptionId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/TellAFriend/GetTellAFriendCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (subscriptionId !== undefined) {
                localVarQueryParameter['SubscriptionId'] = subscriptionId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share your Tell a Friend code by sending an email to self.
         * @param {ShareCodeByEmailToSelfCommand} shareCodeByEmailToSelfCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tellAFriendShareCodeByEmailToSelf: async (shareCodeByEmailToSelfCommand: ShareCodeByEmailToSelfCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'shareCodeByEmailToSelfCommand' is not null or undefined
            assertParamExists('tellAFriendShareCodeByEmailToSelf', 'shareCodeByEmailToSelfCommand', shareCodeByEmailToSelfCommand)
            const localVarPath = `/employeeportal/TellAFriend/ShareCodeByEmailToSelf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shareCodeByEmailToSelfCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update preferred gift.
         * @param {UpdateGiftCommand} updateGiftCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tellAFriendUpdateGift: async (updateGiftCommand: UpdateGiftCommand, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGiftCommand' is not null or undefined
            assertParamExists('tellAFriendUpdateGift', 'updateGiftCommand', updateGiftCommand)
            const localVarPath = `/employeeportal/TellAFriend/UpdateGift`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGiftCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TellAFriendApi - functional programming interface
 * @export
 */
export const TellAFriendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TellAFriendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Tell a Friend code.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tellAFriendGetTellAFriendCode(subscriptionId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TellAFriendCodeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tellAFriendGetTellAFriendCode(subscriptionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Share your Tell a Friend code by sending an email to self.
         * @param {ShareCodeByEmailToSelfCommand} shareCodeByEmailToSelfCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tellAFriendShareCodeByEmailToSelf(shareCodeByEmailToSelfCommand: ShareCodeByEmailToSelfCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tellAFriendShareCodeByEmailToSelf(shareCodeByEmailToSelfCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update preferred gift.
         * @param {UpdateGiftCommand} updateGiftCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tellAFriendUpdateGift(updateGiftCommand: UpdateGiftCommand, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tellAFriendUpdateGift(updateGiftCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TellAFriendApi - factory interface
 * @export
 */
export const TellAFriendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TellAFriendApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Tell a Friend code.
         * @param {number} [subscriptionId] Subscription id.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tellAFriendGetTellAFriendCode(subscriptionId?: number, options?: any): AxiosPromise<TellAFriendCodeDto> {
            return localVarFp.tellAFriendGetTellAFriendCode(subscriptionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share your Tell a Friend code by sending an email to self.
         * @param {ShareCodeByEmailToSelfCommand} shareCodeByEmailToSelfCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tellAFriendShareCodeByEmailToSelf(shareCodeByEmailToSelfCommand: ShareCodeByEmailToSelfCommand, options?: any): AxiosPromise<void> {
            return localVarFp.tellAFriendShareCodeByEmailToSelf(shareCodeByEmailToSelfCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update preferred gift.
         * @param {UpdateGiftCommand} updateGiftCommand 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tellAFriendUpdateGift(updateGiftCommand: UpdateGiftCommand, options?: any): AxiosPromise<void> {
            return localVarFp.tellAFriendUpdateGift(updateGiftCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TellAFriendApi - object-oriented interface
 * @export
 * @class TellAFriendApi
 * @extends {BaseAPI}
 */
export class TellAFriendApi extends BaseAPI {
    /**
     * 
     * @summary Get Tell a Friend code.
     * @param {number} [subscriptionId] Subscription id.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TellAFriendApi
     */
    public tellAFriendGetTellAFriendCode(subscriptionId?: number, options?: any) {
        return TellAFriendApiFp(this.configuration).tellAFriendGetTellAFriendCode(subscriptionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share your Tell a Friend code by sending an email to self.
     * @param {ShareCodeByEmailToSelfCommand} shareCodeByEmailToSelfCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TellAFriendApi
     */
    public tellAFriendShareCodeByEmailToSelf(shareCodeByEmailToSelfCommand: ShareCodeByEmailToSelfCommand, options?: any) {
        return TellAFriendApiFp(this.configuration).tellAFriendShareCodeByEmailToSelf(shareCodeByEmailToSelfCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update preferred gift.
     * @param {UpdateGiftCommand} updateGiftCommand 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TellAFriendApi
     */
    public tellAFriendUpdateGift(updateGiftCommand: UpdateGiftCommand, options?: any) {
        return TellAFriendApiFp(this.configuration).tellAFriendUpdateGift(updateGiftCommand, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UploadApi - axios parameter creator
 * @export
 */
export const UploadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete a file. Does not indicate whether the actual file exists and is deleted. Note: Filepond send the Id in the request payload, so no parameters are accepted in this method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDelete: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Upload/Delete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file. Use FilePond to upload a file called \"filePond\" to this end-point. It is not designed to be called in the generated client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUpload: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/employeeportal/Upload/Upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtToken required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UploadApi - functional programming interface
 * @export
 */
export const UploadApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UploadApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete a file. Does not indicate whether the actual file exists and is deleted. Note: Filepond send the Id in the request payload, so no parameters are accepted in this method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDelete(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a file. Use FilePond to upload a file called \"filePond\" to this end-point. It is not designed to be called in the generated client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadUpload(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadUpload(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UploadApi - factory interface
 * @export
 */
export const UploadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UploadApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete a file. Does not indicate whether the actual file exists and is deleted. Note: Filepond send the Id in the request payload, so no parameters are accepted in this method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDelete(options?: any): AxiosPromise<void> {
            return localVarFp.uploadDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file. Use FilePond to upload a file called \"filePond\" to this end-point. It is not designed to be called in the generated client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUpload(options?: any): AxiosPromise<string> {
            return localVarFp.uploadUpload(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UploadApi - object-oriented interface
 * @export
 * @class UploadApi
 * @extends {BaseAPI}
 */
export class UploadApi extends BaseAPI {
    /**
     * 
     * @summary Delete a file. Does not indicate whether the actual file exists and is deleted. Note: Filepond send the Id in the request payload, so no parameters are accepted in this method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadDelete(options?: any) {
        return UploadApiFp(this.configuration).uploadDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file. Use FilePond to upload a file called \"filePond\" to this end-point. It is not designed to be called in the generated client.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UploadApi
     */
    public uploadUpload(options?: any) {
        return UploadApiFp(this.configuration).uploadUpload(options).then((request) => request(this.axios, this.basePath));
    }
}


