


































import Component, { mixins } from 'vue-class-component'

import { AuthenticationApi } from '@/api'
import { State } from '@/mixins/state'

import Content from '@/components/Content.vue'
import FormInput, { InputField } from '@/components/Form/Input.vue'
import FormButton from '@/components/Form/Button.vue'
import Logo from '@/components/Layout/Logo.vue'
import Loader from '@/components/Global/Loader.vue'

@Component({
  components: {
    Content,
    Logo,
    FormButton,
    FormInput,
    Loader
  }
})
export default class AuthRequestPassword extends mixins(State) {
  origin = ''

  emailAddress: InputField = {
    name: 'emailAddress',
    value: '',
    required: true,
    type: 'email',
    placeholder: 'E-mailadres',
    errors: [],
    inline: true,
    icon: 'envelope'
  }

  mounted () {
    // Save origin, so we can return visitor back to its origin (eg registration flow).
    // When no origin is provided, this resets the saved origin.
    this.origin = this.$route.params.origin
    this.$store.commit('setRequestPasswordOrigin', this.origin)
  }

  private requestPassword () {
    if (!this.validateInput()) {
      return
    }

    this.setState(this.states.LOADING)
    const api = new AuthenticationApi()

    api.authenticationRequestPasswordResetToken({
      emailAddress: this.emailAddress.value
    })
      .then(() => {
        this.setState(this.states.COMPLETE)
      })
      .catch(() => {
        this.setState(this.states.ERROR)
      })
  }

  private validateInput () {
    return (this.$refs.emailAddress as FormInput).validate()
  }
}
